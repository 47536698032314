import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterAcoes(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/acoes`);
    },

    obterAcao(idCnt, convenioAcaoId) {
        return axiosJwt.get(`${urlBase}/${idCnt}/acoes/${convenioAcaoId}`);
    },

    inserirAcao(acao) {
        return axiosJwt.post(`${urlBase}/${acao.idCnt}/acoes`, acao);
    },

    atualizarAcao(acao) {
        return axiosJwt.put(`${urlBase}/${acao.idCnt}/acoes`, acao);
    },

    excluirAcao(idCnt, convenioAcaoId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/acoes/${convenioAcaoId}`);
    },

    obterMetasTipos(idCnt, convenioAcaoId) {
        return axiosJwt.get(`${urlBase}/${idCnt}/acoes/${convenioAcaoId}/metas/tipos`);
    },

    inserirMeta(meta) {
        return axiosJwt.post(`${urlBase}/${meta.idCnt}/acoes/${meta.convenioAcaoId}/metas`, meta);
    },

    atualizarMeta(meta) {
        return axiosJwt.put(`${urlBase}/${meta.idCnt}/acoes/${meta.convenioAcaoId}/metas`, meta);
    },

    excluirMeta(idCnt, convenioAcaoId, convenioAcaoMetaId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/acoes/${convenioAcaoId}/metas/${convenioAcaoMetaId}`);
    },
};
