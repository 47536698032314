import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterReceitas(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/receitas`);
    },

    obterTiposFontes(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/receitas/fontes`);
    },

    vincularReceitas(receitas) {
        return axiosJwt.post(`${urlBase}/${receitas.idCnt}/receitas/vincular`, receitas);
    },

    atualizarReceita(receita) {
        return axiosJwt.post(`${urlBase}/${receita.idCnt}/receitas/atualizar`, receita);
    },

    excluirReceita(idCnt, convenioReceitaId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/receitas/${convenioReceitaId}`);
    },

    reconhecerReceitas(idCnt, reconhecimentoDeDespesaDto) {
        return axiosJwt.post(`${urlBase}/${idCnt}/receitas/reconhecerreceitas`, reconhecimentoDeDespesaDto);
    },
};
