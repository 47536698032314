<template>
    <detalhe titulo="Diretoria" :size="size">
        <span v-if="convenio?.camposComplementares?.diretoria"
            >({{ convenio.camposComplementares.codDiretoria }})
            {{ convenio.camposComplementares.diretoria }}</span
        >
    </detalhe>
    <detalhe titulo="Situação do Convênio" :size="size">
        <span v-if="convenio?.camposComplementares?.situacaoConvenio"
            >({{ convenio.camposComplementares.codSituacaoConvenio }})
            {{ convenio.camposComplementares.situacaoConvenio }}</span
        >
    </detalhe>
    <detalhe titulo="Verificação de Saldo" :size="size">
        <span v-if="convenio?.camposComplementares?.tipoConsistencia"
            >({{ convenio.camposComplementares.codTipoConsistencia }})
            {{ convenio.camposComplementares.tipoConsistencia }}</span
        >
    </detalhe>
    <detalhe titulo="Tipo do Rateio" :size="size">
        <span v-if="convenio?.camposComplementares?.tipoRateio"
            >({{ convenio.camposComplementares.codTipoRateio }})
            {{ convenio.camposComplementares.tipoRateio }}</span
        >
    </detalhe>
    <detalhe titulo="Pré-comprometimento" :size="size">
        <span v-if="convenio?.camposComplementares?.usaPreComprometimento"
            >({{ convenio.camposComplementares.codUsaPreComprometimento }})
            {{ convenio.camposComplementares.usaPreComprometimento }}</span
        >
    </detalhe>
</template>

<script>
export default {
    props: {
        convenio: {
            type: Object,
        },
    },

    data() {
        return {
            size: '150',
        };
    },
};
</script>
