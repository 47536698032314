<template>
    <div class="grid" v-if="convenio">
        <div class="col-12 mt-4">
            <h5 class="heading-1">
                <span>APORTES</span>
            </h5>
        </div>
        <div class="col-3" v-for="aporte in convenio.aportes" :key="aporte.tipoAporte">
            <div class="overview-box card">
                <div class="overview-box-value">{{ $numeral(aporte.valor).format('$0,0.00') }}</div>
                <div class="overview-box-title">
                    <span class="p-text-primary">{{ aporte.tipoAporteDescr }}</span>
                </div>
                <div class="overview-box-status">{{ $numeral(aporte.percentual).format('0,0.00') }}%</div>
            </div>
        </div>
        <div class="col-3">
            <div class="overview-box card">
                <div class="overview-box-value">
                    {{ $numeral(convenio.totalAportes).format('$0,0.00') }}
                </div>
                <div class="overview-box-title">
                    <span class="p-text-primary">TOTAL</span>
                </div>
                <div class="overview-box-status">
                    {{ $numeral(convenio.percentualTotalAportes).format('0,0.00%') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        convenio: {
            type: Object,
        },
    },
};
</script>