<template>
    <Button
        :label="`Excluir (${transacoesId ? transacoesId.length : 0})`"
        icon="pi pi-trash"
        iconPos="left"
        class="p-button-danger"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-10')"
        @click="confirmar()"
        :disabled="!transacoesId || transacoesId.length == 0"
    />
</template>

<script>
import Services from './services';

export default {
    emits: ['obterTransacoesAtualizadas'],

    props: {
        transacoesId: {
            type: Array,
        },
    },

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir as transações selecionadas?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            let transcoesDto = { transacoesId: [...this.transacoesId] };
            Services.excluirTransacoesBancarias(this.$route.params.id, transcoesDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({ severity: 'success', summary: 'Transações', detail: 'Excluídas com sucesso', life: 3000 });
                    this.$emit('obterTransacoesAtualizadas');
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Transções', detail: response.errors[0], life: 3000 });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>