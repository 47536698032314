<template>
    <div class="grid mt-2">
        <div class="col-12">
            <tabela :paginator="false" :data="relatorios" dataKey="id" stateKey="dt-rm-convenios-execucao-direta-despesa-relatorios">
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex">
                                <BtnVisualizarPDF v-if="convenio && slotProps.data" :convenio="convenio" :relatorio="slotProps.data" />
                                <btn-download v-if="convenio && slotProps.data" :convenio="convenio" :relatorio="slotProps.data" />
                            </div>
                        </template>
                    </Column>
                    <Column field="nome" header="Arquivo" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.nome }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
    </div>
</template>

<script>
import BtnVisualizarPDF from './BtnVisualizarPDF.vue';
import BtnDownload from './BtnDownload.vue';

export default {
    props: {
        convenio: {
            type: Object,
        },
    },

    components: { BtnVisualizarPDF, BtnDownload },

    data() {
        return {
            relatorio: null,
            relatorios: [
                {
                    id: 1,
                    nome: 'Demonstrativo Sintético',
                    tipo: 'demonstrativosintetico',
                    icon: 'pi pi-file-pdf',
                    arquivo: 'DemonstrativoSintetico',
                    tipoArquivo: 'pdf',
                },
                {
                    id: 2,
                    nome: 'Pagamentos Efetuados',
                    tipo: 'pagamentosefetuados',
                    icon: 'pi pi-file-pdf',
                    arquivo: 'PagamentosEfetuados',
                    tipoArquivo: 'pdf',
                },
                {
                    id: 3,
                    nome: 'Pagamentos Por Ação',
                    tipo: 'pagamentosporacao',
                    icon: 'pi pi-file-pdf',
                    arquivo: 'PagamentosPorAcao',
                    tipoArquivo: 'pdf',
                },
            ],
        };
    },
};
</script>
