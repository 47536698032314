<template>
    <font-awesome-icon icon="search" @click="abrir()" size="lg" class="icon-button ml-2" title="Visualizar Pdf" />
    <visualizar-PDF :mostrar="mostrar" :titulo="relatorio?.nome" @cancelar="fechar()" v-if="arquivo">
        <iframe v-if="arquivo" v-bind:src="arquivo" style="width: 100%; height: 850px"></iframe>
    </visualizar-PDF>
</template>

<script>
import VisualizarPDF from './VisualizarPDF';
import Service from './services';

export default {
    components: { VisualizarPDF },

    props: {
        convenio: { type: Object },
        relatorio: { type: Object },
    },

    data() {
        return {
            mostrar: false,
            arquivo: [],
        };
    },

    methods: {
        abrir() {
            this.obterPdf();
            this.mostrar = true;
        },

        fechar() {
            if (!this.mostrar) return;

            this.arquivo = null;
            this.mostrar = false;
        },

        obterPdf() {
            this.$store.dispatch('addRequest');
            Service.obterRelatorio(this.convenio.idCnt, this.relatorio.tipo).then((response) => {
                if (response) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    this.arquivo = window.URL.createObjectURL(blob);
                    this.mostrar = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
