<template>
    <div class="grid mt-2" v-if="item">
        <div class="col-12">
            <h5 class="heading-1">
                <span>ADITIVOS</span>
            </h5>
            <tabela
                :data="item?.aditivos"
                dataKey="nSeqAditivo"
                :globalFilterFields="[
                    'nSeqAditivo',
                    'classificacaoAditivoDescr',
                    'efeitoAditivoDescr',
                    'tipoAditivoDescr',
                    'dataProrrogacao',
                    'dataAssinatura',
                    'dataInicioVigencia',
                ]"
                stateKey="dt-rm-convenios-execucao-direta-item-aditivos"
                :mostrarPesquisa="false"
                :mostrarHeader="false"
            >
                <template #conteudo>
                    <Column field="nSeqAditivo" header="Sequencial" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.nSeqAditivo }}
                        </template>
                    </Column>
                    <Column field="classificacaoAditivoDescr" header="Classificação" :sortable="true">
                        <template #body="slotProps">
                            ({{ slotProps.data.classificacaoAditivo }})
                            {{ slotProps.data.classificacaoAditivoDescr }}
                        </template>
                    </Column>
                    <Column field="efeitoAditivoDescr" header="Efeito Aditivo" :sortable="true">
                        <template #body="slotProps">
                            ({{ slotProps.data.efeitoAditivo }})
                            {{ slotProps.data.efeitoAditivoDescr }}
                        </template>
                    </Column>
                    <Column field="tipoAditivoDescr" header="Tipo Aditivo" :sortable="true">
                        <template #body="slotProps"> ({{ slotProps.data.tipoAditivo }}) {{ slotProps.data.tipoAditivoDescr }} </template>
                    </Column>
                    <Column field="valor" header="Valor" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="percentualAditivo" header="%" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.percentualAditivo).format('0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="dataProrrogacao" header="Data Prorrogação" :sortable="true">
                        <template #body="slotProps">
                            {{ $dateFormat(slotProps.data.dataProrrogacao, 'DD/MM/YYYY') }}
                        </template>
                    </Column>
                    <Column field="dataAssinatura" header="Data Assinatura" :sortable="true">
                        <template #body="slotProps">
                            {{ $dateFormat(slotProps.data.dataAssinatura, 'DD/MM/YYYY') }}
                        </template>
                    </Column>
                    <Column field="dataInicioVigencia" header="Data Início Vigência" :sortable="true">
                        <template #body="slotProps">
                            {{ $dateFormat(slotProps.data.dataInicioVigencia, 'DD/MM/YYYY') }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
        },
    },

    data() {
        return {};
    },

    methods: {},
};
</script>
