<template>
    <div class="grid mt-2">
        <div class="col-12">
            <h5 class="heading-1">
                <span>ANEXOS</span>
            </h5>
            <tabela
                :data="anexos"
                dataKey="convenioAnexoId"
                :globalFilterFields="['nomeArquivo', 'tipo']"
                stateKey="dt-rm-convenios-execucao-direta-despesa-anexos"
            >
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex">
                                <btn-excluir-anexo-despesa
                                    :anexo="slotProps.data"
                                    :services="services"
                                    :convenio="convenio"
                                    @obterAnexosAtualizados="obterAnexosAtualizados()"
                                    class="ml-2"
                                ></btn-excluir-anexo-despesa>
                            </div>
                        </template>
                    </Column>
                    <Column field="nomeArquivo" header="Arquivo" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.nomeArquivo }}
                        </template>
                    </Column>
                    <Column field="tipo" header="Tipo" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.tipo }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
    </div>
</template>

<script>
import BtnExcluirAnexoDespesa from './BtnExcluirAnexoDespesa';

export default {
    emits: ['obterAnexosAtualizados'],

    props: {
        anexos: { type: Object },
        convenio: { type: Object },
        services: { type: Object },
    },

    components: { BtnExcluirAnexoDespesa },

    methods: {
        obterAnexosAtualizados() {
            this.$emit('obterAnexosAtualizados');
        },
    },
};
</script>
