<template>
    <Button
        :label="`Atualizar (${transacoesId ? transacoesId.length : 0})`"
        icon="pi pi-pencil"
        iconPos="left"
        class="p-button"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-11')"
        @click="abrir()"
        :disabled="!transacoesId || transacoesId.length == 0"
    />
    <Dialog
        v-model:visible="mostrarModal"
        :modal="true"
        :style="{ width: '40vw' }"
        @hide="cancelar()"
        @show="preencher()"
        :contentStyle="{ overflow: 'visible' }"
    >
        <template #header>
            <h5>Atualizar Tipo da Transação</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <label class="required">Tipos de Transações</label>
                <Dropdown v-model="tipoTransacao" :options="tiposTransacoes" optionLabel="descricao" optionValue="convenioTipoTransacaoId" :filter="true" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmar()" :disabled="!tipoTransacao" />
        </template>
    </Dialog>
</template>

<script>
import Services from './services';

export default {
    emits: ['obterTransacoesAtualizadas'],

    props: {
        transacoesId: {
            type: Array,
        },
    },

    data() {
        return {
            tiposTransacoes: null,
            mostrarModal: false,
            tipoTransacao: null,
        };
    },

    methods: {
        abrir() {
            this.mostrarModal = true;
        },

        cancelar() {
            this.mostrarModal = false;
            this.tipoTransacao = null;
        },

        obterTiposTransacoes() {
            this.$store.dispatch('addRequest');
            Services.obterTiposTransacoes(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.tiposTransacoes = [...response.data];
                } else {
                    this.tiposTransacoes = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencher() {
            this.obterTiposTransacoes();
        },

        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja atualizar as transações selecionadas?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.atualizar();
                },
            });
        },

        atualizar() {
            this.$store.dispatch('addRequest');
            let transcoesDto = { transacoesId: [...this.transacoesId], tipoTransacaoId: this.tipoTransacao };
            Services.atualizarTransacoesBancarias(this.$route.params.id, transcoesDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({ severity: 'success', summary: 'Transações', detail: 'Atualizadas com sucesso', life: 3000 });
                    this.$emit('obterTransacoesAtualizadas');
                    this.cancelar();
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Transções', detail: response.errors[0], life: 3000 });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>