<template>
    <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex">
            <div class="w-full">
                <span class="block text-500 font-medium mb-3"
                    >{{ titulo }}<font-awesome-icon icon="info-circle" class="icon-button text-400 ml-2" :title="regra"
                /></span>
                <div class="text-900 font-medium text-xl text-right">
                    <span class="ml-2 pt-2"
                        ><span v-if="tipo == 'V'" :class="{ 'text-primary': valor > 0, 'p-error': valor < 0 }">{{ $numeral(valor).format('$0,0.00') }}</span>
                        <span v-if="tipo == 'V2'">{{ $numeral(valor).format('$0,0.00') }}</span>
                        <span v-if="tipo == 'D'">{{ $dateFormat(data, 'DD/MM/YYYY') }}</span></span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
        },

        valor: {
            type: Number,
        },

        data: {
            type: String,
        },

        tipo: {
            type: String,
        },

        regra: {
            type: String,
        },
    },
};
</script>