<template>
    <Dialog v-model:visible="display" :modal="true" :style="{ width: '60vw' }" @show="preencher()" @hide="cancelar()">
        <template #header>
            <h5>Convenio Parceiro</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-8">
                <label>Preencha o campo de convênio parceiro</label>
                <InputText type="text" v-model="convenioParceiroTemp" @input="v$.convenioParceiroTemp.$touch()" />
                <small class="p-error" v-if="v$.convenioParceiroTemp.$error">O campo de convênio parceiro é obrigatória</small>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmarSalvar()" :disabled="naoPermiteSalvar" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            display: false,
            convenioParceiroTemp: null,
        };
    },
    validations() {
        return { convenioParceiroTemp: { required } };
    },
    props: {
        mostrar: { type: Boolean, default: false },
        convenioParceiro: { type: String },
        erros: { type: Array }
    },
    emits: ['cancelar', 'salvar'],
    watch: {
        mostrar() {
            this.display = this.mostrar;
        },
    },
    computed: {
        naoPermiteSalvar() {
            return this.v$.$invalid;
        },
    },
    methods: {
        cancelar() { this.$emit('cancelar') },
        resetar() {
            this.convenioParceiroTemp = null;
            this.$nextTick(() => this.v$.$reset());
        },
        preencher() {
            this.convenioParceiroTemp = this.convenioParceiro;
            this.v$.$touch();
        },
        confirmarSalvar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja atualizar o convênio parceiro?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },
        salvar() { this.$emit('salvar', this.convenioParceiroTemp) }
    },
};
</script>
