<template>
    <font-awesome-icon
        icon="trash"
        @click="confirmar()"
        size="lg"
        class="icon-button p-text-danger"
        title="Excluir"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-19')"
    />
</template>

<script>
export default {
    props: {
        services: { type: Object },
        convenio: { type: Object },
        anexo: { type: Object },
    },

    emits: ['obterAnexosAtualizados'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir o anexo?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => this.excluir(),
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            this.services.excluirAnexoDespesa(this.convenio.idCnt, this.anexo.convenioAnexoId).then((response) => {
                this.$toast.add({
                    severity: response?.success ? 'success' : 'error',
                    summary: 'Anexo',
                    detail: response?.success ? 'Anexo excluído com sucesso' : response.errors[0],
                    life: 3000,
                });

                if (response?.success) this.$emit('obterAnexosAtualizados');

                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
