<template>
    <div class="col-12" v-show="operacao == 'despesas'">
        <h5 class="heading-1">
            <span>Atualizar</span>
        </h5>
        <div v-if="despesa">
            <detalhe titulo="IdMov">
                {{ despesa.idMov }}
            </detalhe>
            <detalhe titulo="Fornecedor">
                {{ despesa.fornecedor }}
            </detalhe>
            <detalhe titulo="Data Emissão">
                {{ $dateFormat(despesa.dataEmissao, 'DD/MM/YYYY') }}
            </detalhe>
            <detalhe titulo="Data Baixa">
                {{ $dateFormat(despesa.dataBaixa, 'DD/MM/YYYY') }}
            </detalhe>
            <detalhe titulo="Documento">
                {{ despesa.documento }}
            </detalhe>
            <detalhe titulo="Histórico">
                {{ despesa.historico }}
            </detalhe>
            <detalhe titulo="Valor">
                {{ $numeral(despesa.valor).format('$0,0.00') }}
            </detalhe>
            <slot></slot>
        </div>
        <btn-inserir-anexo-despesa @click="operacao = 'anexos'" />
        <Button label="Salvar" icon="pi pi-save" @click="confirmarSalvar()" :disabled="invalido" />
        <btn-voltar :back="fechar" class="ml-0"></btn-voltar>
        <anexos :anexos="anexos" :convenio="convenio" :services="services" @obterAnexosAtualizados="obterAnexosAtualizados()" />
    </div>

    <div class="col-12 mt-2" v-if="operacao == 'anexos'">
        <inserir-anexo-despesa-form @fechar="operacao = 'despesas'" @salvar="salvarAnexo($event)" />
    </div>
</template>

<script>
import InserirAnexoDespesaForm from './InserirAnexoDespesaForm';
import BtnInserirAnexoDespesa from './BtnInserirAnexoDespesa';
import Anexos from './Anexos';

export default {
    components: {
        InserirAnexoDespesaForm,
        BtnInserirAnexoDespesa,
        Anexos,
    },

    emits: ['salvar', 'salvarAnexo', 'fechar', 'obterAnexosAtualizados'],

    data() {
        return {
            operacao: 'despesas',
        };
    },

    props: {
        despesa: { type: Object },
        anexos: { type: Object },
        services: { type: Object },
        convenio: { type: Object },
        invalido: {
            type: Boolean,
            default: () => true,
        },
    },

    methods: {
        fechar() {
            this.$emit('fechar');
        },

        obterAnexosAtualizados() {
            this.$emit('obterAnexosAtualizados');
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja atualizar a despesa`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$emit('salvar');
                },
            });
        },

        salvarAnexo(anexo) {
            let anexoDto = {
                idCnt: this.despesa.idCnt,
                convenioDespesaId: this.despesa.convenioDespesaId,
                arquivo: anexo.arquivo,
                tipo: anexo.tipo.replace(/\s/g, ''),
            };
            this.$emit('salvarAnexo', anexoDto);
        },
    },
};
</script>
