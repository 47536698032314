<template>
    <div class="grid mt-2">
        <div class="col-12" v-show="operacao == 'L'">
            <h5 class="heading-1">
                <span>METAS</span>
            </h5>
            <tabela
                :data="metas"
                dataKey="convenioAcaoMetaId"
                :globalFilterFields="['descricao', 'aplicacaoMeta']"
                stateKey="dt-rm-convenios-execucao-direta-acoes-metas"
            >
                <template #botoes>
                    <btn-inserir-meta @click="operacao = 'I'"></btn-inserir-meta>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex">
                                <btn-atualizar-meta @click="atualizar(slotProps.data)" class="ml-2"></btn-atualizar-meta>
                                <btn-excluir-meta
                                    :convenio="convenio"
                                    :acao="acao"
                                    :meta="slotProps.data"
                                    :services="services"
                                    @obterAcaoAtualizada="obterAcaoAtualizada()"
                                    class="ml-2"
                                ></btn-excluir-meta>
                            </div>
                        </template>
                    </Column>
                    <Column field="descricao" header="Meta" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricao }}
                        </template>
                    </Column>
                    <Column field="tipo" header="Tipo" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.tipo }}
                        </template>
                    </Column>
                    <Column field="quantidade" header="Quantidade" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.quantidade).format('0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="aplicacaoMeta" header="Aplicação" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.aplicacaoMeta }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
        <div class="col-12" v-if="operacao != 'L' && operacao != 'D'">
            <meta-form
                :acao="acao"
                :meta="meta"
                :convenio="convenio"
                :operacao="operacao"
                :services="services"
                @fechar="operacao = 'L'"
                @obterAcaoAtualizada="obterAcaoAtualizada()"
            ></meta-form>
        </div>
        <slot v-if="operacao == 'L'"></slot>
    </div>
</template>

<script>
import BtnInserirMeta from './BtnInserirMeta';
import BtnExcluirMeta from './BtnExcluirMeta';
import BtnAtualizarMeta from './BtnAtualizarMeta';
import MetaForm from './MetaForm';

export default {
    emits: ['atualizar'],

    props: {
        convenio: {
            type: Object,
        },

        acao: {
            type: Object,
        },

        services: {
            type: Object,
        },
    },

    components: {
        BtnInserirMeta,
        MetaForm,
        BtnAtualizarMeta,
        BtnExcluirMeta,
    },

    data() {
        return {
            metas: [],
            meta: null,
            operacao: 'L',
        };
    },

    methods: {
        obterAcao() {
            this.$store.dispatch('addRequest');
            this.services.obterAcao(this.$route.params.id, this.acao.convenioAcaoId).then((response) => {
                if (response?.success) {
                    this.metas = [...response.data.metas];
                } else {
                    this.metas = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterAcaoAtualizada() {
            this.$emit('obterAcoesAtualizadas');
            this.obterAcao();
        },

        atualizar(meta) {
            this.operacao = 'A';
            this.meta = meta;
        },
    },

    created() {
        this.obterAcao();
    },
};
</script>