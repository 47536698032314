<template>
    <slot></slot>
    <h5 class="heading-1">
        <span>Pesquisar Movimentos</span>
    </h5>
    <div class="formgrid grid p-fluid">
        <div class="field col-12 md:col-2">
            <label>IDMOV</label>
            <InputText type="text" v-model="idMov" />
        </div>
        <div class="field col-12 md:col-2">
            <label>Código</label>
            <InputMask v-model="codigo" mask="9.9.99" />
        </div>
        <div class="field col-12 md:col-2">
            <label>Status IDMOV</label>
            <Dropdown v-model="status" :options="tiposStatus" optionLabel="label" optionValue="value" />
        </div>
        <div class="field col-12 md:col-3">
            <label>Data Emissão (DE)</label>
            <Calendar
                v-model="dataEmissaoDe"
                :monthNavigator="true"
                :yearNavigator="true"
                :maxDate="dataEmissaoAte"
                :manualInput="false"
                :showButtonBar="true"
            />
        </div>
        <div class="field col-12 md:col-3">
            <label>Data Emissão (ATÉ)</label>
            <Calendar
                v-model="dataEmissaoAte"
                :monthNavigator="true"
                :yearNavigator="true"
                :minDate="dataEmissaoDe"
                :manualInput="false"
                :showButtonBar="true"
            />
        </div>
        <div class="field col-12 md:col-6">
            <label>Fornecedor</label>
            <InputText type="text" v-model="fornecedor" />
        </div>
        <div class="field col-12 md:col-6">
            <label>Projeto</label>
            <Dropdown v-model="projeto" :options="projetos" optionLabel="nome" optionValue="codProjeto" :filter="true" />
        </div>
    </div>
    <Button label="Pesquisar" icon="pi pi-search" iconPos="left" class="p-button" @click="pesquisar()" />
    <Button label="Vincular Selecionados" class="ml-2" icon="pi pi-link" @click="vincular()" :disabled="movimentosSelecionados.length < 1 || invalido" />
    <btn-voltar :back="fechar"></btn-voltar>
    <h5 class="heading-1">
        <span>Movimentos</span>
    </h5>
    <tabela
        :data="movimentos"
        dataKey="idMov"
        :globalFilterFields="['idMov', 'codTmv', 'fornecedor', 'historico']"
        stateKey="dt-rm-convenios-execucao-movimentos"
    >
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <Checkbox :value="slotProps.data.idMov" v-model="movimentosSelecionados" />
                </template>
            </Column>
            <Column field="idMov" header="ID MOV" :sortable="true">
                <template #body="slotProps">{{ slotProps.data.idMov }}</template>
            </Column>
            <Column field="codTmv" header="Cód. Mov" :sortable="true">
                <template #body="slotProps">{{ slotProps.data.codTmv }}</template>
            </Column>
            <Column field="status" header="Status" :sortable="true">
                <template #body="slotProps">{{ slotProps.data.status }}</template>
            </Column>
            <Column field="dataEmissao" header="Data Emissão" :sortable="true">
                <template #body="slotProps">{{ $dateFormat(slotProps.data.dataEmissao, 'DD/MM/YYYY') }}</template>
            </Column>
            <Column field="fornecedor" header="Fornecedor" :sortable="true">
                <template #body="slotProps">{{ slotProps.data.fornecedor }}</template>
            </Column>
            <Column field="historico" header="Histórico" :sortable="true">
                <template #body="slotProps">{{ slotProps.data.historico }}</template>
            </Column>
            <Column field="valorPrevisto" header="Valor" :sortable="true">
                <template #body="slotProps">
                    <div class="white-space-nowrap text-right">
                        <span>{{ $numeral(slotProps.data.valorPrevisto).format('$0,0.00') }}</span>
                    </div>
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
import Services from './services';

export default {
    props: {
        convenio: {
            type: Object,
        },

        tipo: {
            type: String,
        },

        tipoReembolso: {
            type: Number,
        },

        invalido: {
            type: Boolean,
            default: () => false,
        },
    },

    emits: ['fechar', 'selecionar'],

    data() {
        return {
            idMov: null,
            codigo: null,
            status: 'T',
            tiposStatus: [
                { label: 'TODOS', value: 'T' },
                { label: 'ABERTO', value: 'A' },
                { label: 'FATURADO', value: 'F' },
                { label: 'QUITADO', value: 'Q' },
            ],
            dataEmissaoDe: null,
            dataEmissaoAte: null,
            fornecedor: null,
            projeto: 'T',
            projetos: null,
            movimentos: null,
            movimentosSelecionados: [],
        };
    },

    methods: {
        obterProjetos() {
            this.$store.dispatch('addRequest');
            Services.obterProjetos()
                .then((response) => {
                    if (response?.success) {
                        this.projetos = [...response.data];
                        this.projetos.unshift({ codProjeto: 'T', nome: 'TODOS' });
                    } else {
                        this.projetos = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        pesquisar() {
            this.$store.dispatch('addRequest');
            let filtro = {
                idCnt: this.convenio.idCnt,
                idMov: this.idMov ? this.idMov : '',
                codigo: this.codigo ? this.codigo : '',
                status: this.status != 'T' ? this.status : '',
                dataEmissaoDe: this.dataEmissaoDe ? this.$moment(this.dataEmissaoDe).format('YYYY-MM-DD') : '',
                dataEmissaoAte: this.dataEmissaoAte ? this.$moment(this.dataEmissaoAte).format('YYYY-MM-DD') : '',
                projeto: this.projeto != 'T' ? this.projeto : '',
                fornecedor: this.fornecedor ? this.fornecedor : '',
                tipo: this.tipo,
                tipoReembolso: this.tipoReembolso,
            };
            Services.obterMovimentos(filtro)
                .then((response) => {
                    if (response?.success) {
                        this.movimentos = [...response.data];
                    } else {
                        this.movimentos = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        fechar() {
            this.$emit('fechar');
        },

        vincular() {
            this.$emit('selecionar', this.movimentosSelecionados);
        },
    },

    created() {
        this.obterProjetos();
    },
};
</script>
