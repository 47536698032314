<template>
    <TabView v-model:activeIndex="activeIndex">
        <TabPanel header="Empresas Beneficiadas">
            <rebs-empresas :convenio="convenio" @atualizar="$emit('atualizar')"></rebs-empresas>
        </TabPanel>
        <!-- <TabPanel header="Títulos"><span>Em desenvolvimento</span></TabPanel> -->
        <!-- <TabPanel header="Anexos"><span>Em desenvolvimento</span></TabPanel> -->
    </TabView>
</template>

<script>
import RebsEmpresas from './RebsEmpresas';

export default {
    emits: ['atualizar'],

    data() {
        return {
            activeIndex: 0,
        };
    },

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        RebsEmpresas,
    },
};
</script>