<template>
    <h5 class="heading-1">
        <span>Atualizar</span>
    </h5>
    <div v-if="receita">
        <detalhe titulo="IdMov" :size="size">
            {{ receita.idMov }}
        </detalhe>
        <detalhe titulo="Descrição" :size="size">
            {{ receita.descricao }}
        </detalhe>
        <detalhe titulo="Data Receita" :size="size">
            {{ $dateFormat(receita.dataReceita, 'DD/MM/YYYY') }}
        </detalhe>
        <detalhe titulo="Valor" :size="size">
            {{ $numeral(receita.valor).format('$0,0.00') }}
        </detalhe>
        <slot></slot>
    </div>
    <Button label="Salvar" icon="pi pi-save" @click="confirmarSalvar()" />
    <btn-voltar :back="fechar" class="ml-0"></btn-voltar>
</template>

<script>
export default {
    props: {
        receita: {
            type: Object,
        },
    },

    methods: {
        fechar() {
            this.$emit('fechar');
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja atualizar a receita`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$emit('salvar');
                },
            });
        },
    },
};
</script>