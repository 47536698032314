<template>
    <font-awesome-icon
        icon="trash"
        @click="confirmar()"
        size="lg"
        class="icon-button p-text-danger"
        title="Excluir"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-06') && acao?.permiteExcluir"
    />
</template>

<script>
export default {
    props: {
        services: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        acao: {
            type: Object,
        },
    },

    emits: ['obterAcoesAtualizadas'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir a ação?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            this.services.excluirAcao(this.convenio.idCnt, this.acao.convenioAcaoId).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Ações',
                        detail: 'Ação excluída com sucesso',
                        life: 3000,
                    });
                    this.$emit('obterAcoesAtualizadas');
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Ações',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
