<template>
    <div class="grid">
        <div class="col-12" v-show="operacao == 'listar'">
            <tabela
                :data="anexos"
                dataKey="convenioAnexoId"
                :globalFilterFields="['chave', 'descricao', 'nomeArquivo']"
                stateKey="dt-rm-convenios-execucao-direta-anexos"
            >
                <template #botoes>
                    <btn-inserir-anexo @click="operacao = 'inserir'"></btn-inserir-anexo>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex">
                                <btn-download :convenio="convenio" :anexo="slotProps.data"></btn-download>
                                <btn-excluir-anexo
                                    :convenio="convenio"
                                    :anexo="slotProps.data"
                                    :services="obterServices()"
                                    @obterAnexosAtualizados="obterAnexos()"
                                    class="ml-2"
                                ></btn-excluir-anexo>
                            </div>
                        </template>
                    </Column>
                    <Column field="chave" header="Chave" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.chave }}
                        </template>
                    </Column>
                    <Column field="descricao" header="Descrição" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricao }}
                        </template>
                    </Column>
                    <Column field="nomeArquivo" header="Arquivo" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.nomeArquivo }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
        <div class="col-12" v-if="operacao == 'inserir'">
            <inserir-anexo-form @fechar="operacao = 'listar'" @salvar="inserir($event)"> </inserir-anexo-form>
        </div>
    </div>
</template>

<script>
import InserirAnexoForm from './InserirAnexoForm';
import BtnInserirAnexo from './BtnInserirAnexo';
import BtnExcluirAnexo from './BtnExcluirAnexo';
import BtnDownload from './BtnDownload';
import Services from './services';

export default {
    emits: ['atualizar'],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        BtnInserirAnexo,
        BtnExcluirAnexo,
        InserirAnexoForm,
        BtnDownload,
    },

    data() {
        return {
            operacao: 'listar',
            anexos: null,
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterAnexos() {
            this.$store.dispatch('addRequest');
            Services.obterAnexos(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.anexos = [...response.data];
                } else {
                    this.anexos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        inserir(anexo) {
            this.$store.dispatch('addRequest');
            let anexoDto = {
                idCnt: this.convenio.idCnt,
                chave: anexo.chave,
                descricao: anexo.descricao,
                arquivo: anexo.arquivo,
            };
            Services.inserirAnexo(anexoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Anexos',
                        detail: 'Anexo inserido com sucesso',
                        life: 3000,
                    });
                    this.operacao = 'listar';
                    this.obterAnexos();
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Anexos',
                        detail: `Falha ao inserir anexo - ${response.errors[0]}`,
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    watch: {
        convenio() {
            if (this.convenio) {
                this.obterAnexos();
            }
        },
    },
};
</script>