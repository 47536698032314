<template>
    <div class="grid">
        <div class="col-12" v-show="operacao == 'listar'">
            <Message :closable="false"
                >TOTAL DESPESAS: <strong>{{ $numeral(valorTotal).format('$0,0.00') }}</strong></Message
            >
            <Message severity="warn" :closable="false" v-if="convenio && !convenio.temContaGerencial"
                ><strong>Para realizar o reconhecimento de receitas faz-se necessário inserir a Conta de Rateio Gerencial</strong></Message
            >
            <tabela
                :data="despesas"
                dataKey="convenioDespesaId"
                :globalFilterFields="['acao', 'descricao', 'idMov']"
                stateKey="dt-rm-convenios-execucao-direta-despesas"
            >
                <template #botoes>
                    <btn-reconhecer-receitas
                        :despesasId="[...despesasSelecionadas]"
                        @obterDespesasAtualizadas="obterDespesas()"
                        :convenio="convenio"
                        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-05') && convenio?.temContaGerencial"
                    ></btn-reconhecer-receitas>
                    <btn-vincular-despesa class="ml-2" @click="operacao = 'vincular'"></btn-vincular-despesa>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #header>
                            <font-awesome-icon icon="check-square" @click="toggleCheckins()" size="lg" class="icon-button" title="Marcar/Desmarcar Todos" />
                        </template>
                        <template #body="slotProps">
                            <div class="flex flex-nowrap">
                                <Checkbox
                                    :value="slotProps.data.convenioDespesaId"
                                    v-model="despesasSelecionadas"
                                    v-if="!slotProps.data.idReconhecimentoReceita"
                                    class="mr-2"
                                />
                                <btn-atualizar-despesa @click="abrirAtualizacao(slotProps.data)"></btn-atualizar-despesa>
                                <btn-excluir-despesa
                                    :convenio="convenio"
                                    :despesa="slotProps.data"
                                    :services="obterServices()"
                                    @obterDespesasAtualizadas="obterDespesas()"
                                    class="ml-1"
                                ></btn-excluir-despesa>
                            </div>
                        </template>
                    </Column>
                    <Column field="idMov" header="ID RM" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.idMov }} </template>
                    </Column>
                    <Column field="fornecedor" header="Fornecedor" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.fornecedor }} </template>
                    </Column>
                    <Column field="dataEmissao" header="Data Emissão" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.dataEmissao, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="dataBaixa" header="Data Baixa" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.dataBaixa, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="documento" header="Documento" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.documento }} </template>
                    </Column>
                    <Column field="historico" header="Histórico" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.historico }}
                        </template>
                    </Column>
                    <Column field="acao" header="Ação" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.acao }}
                        </template>
                    </Column>
                    <Column field="valor" header="Valor" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="percentualSebrae" header="Perc. Sebrae" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.percentualSebrae).format('0,0.00') }}%</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="percentualConvenente" header="Perc. Convenente" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.percentualConvenente).format('0,0.00') }}%</span>
                            </div>
                            <div class="text-right">
                                <span v-if="slotProps.data.idReconhecimentoReceita"
                                    ><small
                                        ><strong>({{ slotProps.data.idReconhecimentoReceita }})</strong></small
                                    ></span
                                >
                                <span v-if="!slotProps.data.idReconhecimentoReceita"
                                    ><small><strong>Reconhecimento de receita não realizada</strong></small></span
                                >
                            </div>
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
        <div class="col-12" v-if="operacao == 'vincular'">
            <pesquisar-movimentos
                :invalido="formVincularDespesaInvalido"
                @fechar="operacao = 'listar'"
                @selecionar="confirmarVincular($event)"
                :convenio="convenio"
                tipo="D"
            >
                <h5 class="heading-1">
                    <span>Vincular Despesas</span>
                </h5>
                <div class="formgrid grid p-fluid">
                    <div class="field col-12">
                        <label class="required">Ação</label>
                        <Dropdown v-model="acao" :options="acoes" optionLabel="descricao" optionValue="convenioAcaoId" />
                    </div>
                    <div class="field col-12 md:col-3">
                        <label class="required">Percentual Sebrae</label>
                        <InputNumber v-model="percentualSebrae" mode="decimal" :minFractionDigits="2" locale="pt-BR" @input="v$.percentualSebrae.$touch()" />
                    </div>
                    <div class="field col-12 md:col-3">
                        <label class="required">Percentual Convenente</label>
                        <InputNumber
                            v-model="percentualConvenente"
                            mode="decimal"
                            :minFractionDigits="2"
                            locale="pt-BR"
                            @input="v$.percentualConvenente.$touch()"
                        />
                    </div>
                </div>
            </pesquisar-movimentos>
        </div>
        <div class="col-12" v-if="operacao == 'atualizar' && despesa">
            <atualizar-form
                :despesa="despesa"
                :anexos="anexos"
                :convenio="convenio"
                :services="obterServices()"
                :invalido="v$.$invalid"
                @fechar="fecharAtualizacao()"
                @salvar="atualizarDespesa()"
                @salvarAnexo="salvarAnexo($event)"
                @obterAnexosAtualizados="obterAnexosAtualizados()"
            >
                <div class="formgrid grid p-fluid">
                    <div class="field col-12">
                        <label class="required">Ação</label>
                        <Dropdown v-model="acao" :options="acoes" optionLabel="descricao" optionValue="convenioAcaoId" />
                    </div>
                    <div class="field col-12 md:col-3">
                        <label class="required">Percentual Sebrae</label>
                        <InputNumber v-model="percentualSebrae" mode="decimal" :minFractionDigits="2" locale="pt-BR" @input="v$.percentualSebrae.$touch()" />
                    </div>
                    <div class="field col-12 md:col-3">
                        <label class="required">Percentual Convenente</label>
                        <InputNumber
                            v-model="percentualConvenente"
                            mode="decimal"
                            :minFractionDigits="2"
                            locale="pt-BR"
                            @input="v$.percentualConvenente.$touch()"
                        />
                    </div>
                </div>
            </atualizar-form>
        </div>
    </div>
</template>

<script>
import BtnVincularDespesa from './BtnVincularDespesa';
import PesquisarMovimentos from '../movimentos/PesquisarMovimentos';
import BtnAtualizarDespesa from './BtnAtualizarDespesa';
import BtnExcluirDespesa from './BtnExcluirDespesa';
import AtualizarForm from './AtualizarForm';
import Services from './services';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BtnReconhecerReceitas from './BtnReconhecerReceitas.vue';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    emits: ['atualizar'],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        BtnVincularDespesa,
        BtnAtualizarDespesa,
        BtnExcluirDespesa,
        PesquisarMovimentos,
        AtualizarForm,
        BtnReconhecerReceitas,
    },

    data() {
        return {
            operacao: 'listar',
            despesas: null,
            valorTotal: 0,
            acao: null,
            percentualSebrae: 0,
            percentualConvenente: 0,
            acoes: null,
            despesa: null,
            despesasSelecionadas: [],
            anexos: null,
        };
    },

    validations() {
        return {
            percentualSebrae: {
                required,
                percentuaisValidos: () => {
                    return this.percentualSebrae + this.percentualConvenente == 100;
                },
            },
            percentualConvenente: {
                required,
                percentuaisValidos: () => {
                    return this.percentualSebrae + this.percentualConvenente == 100;
                },
            },
        };
    },

    computed: {
        formVincularDespesaInvalido() {
            return this.v$.percentualSebrae.$invalid || this.v$.percentualConvenente.$invalid;
        },
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterDespesas() {
            this.$store.dispatch('addRequest');
            Services.obterDespesas(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.despesas = [...response.data.despesas];
                    this.valorTotal = response.data.valorTotal;
                } else {
                    this.despesas = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterAcoes() {
            this.$store.dispatch('addRequest');
            Services.obterAcoes(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.acoes = [...response.data.acoes];
                    if (this.acoes[0]) {
                        this.acao = this.acoes[0].convenioAcaoId;
                        if (this.despesa) {
                            this.acao = this.despesa.convenioAcaoId;
                        }
                    }
                } else {
                    this.acoes = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterPercentuaisAcao() {
            this.$store.dispatch('addRequest');
            Services.obterPercentuaisAcao(this.$route.params.id, this.acao).then((response) => {
                if (response?.success) {
                    this.percentualSebrae = response.data.percentualSebrae;
                    this.percentualConvenente = response.data.percentualConvenente;
                } else {
                    this.percentualSebrae = 0;
                    this.percentualConvenente = 0;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterAnexos() {
            this.$store.dispatch('addRequest');
            Services.obterAnexosDespesa(this.$route.params.id, this.despesa.convenioDespesaId).then((response) => {
                this.anexos = response?.success && response.data ? [...response.data] : null;
                this.$store.dispatch('removeRequest');
            });
        },

        obterAnexosAtualizados() {
            this.obterAnexos();
        },

        confirmarVincular(idsRm) {
            this.$confirm.require({
                message: `Tem certeza que deseja vincular os movimentos selecionados`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.vincular(idsRm);
                },
            });
        },

        vincular(idsRm) {
            this.$store.dispatch('addRequest');
            let despesas = {
                idCnt: this.convenio.idCnt,
                convenioAcaoId: this.acao,
                idsRm: idsRm,
                percentualSebrae: this.percentualSebrae,
                percentualConvenente: this.percentualConvenente,
            };
            Services.vincularDespesas(despesas).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Despesas',
                        detail: 'Vinculos realizados com sucesso',
                        life: 3000,
                    });
                    this.operacao = 'listar';
                    this.obterDespesas();
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Despesas',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        abrirAtualizacao(despesa) {
            this.despesa = despesa;
            this.operacao = 'atualizar';
            this.acao = despesa?.convenioAcaoId;
            this.percentualSebrae = despesa?.percentualSebrae;
            this.percentualConvenente = despesa?.percentualConvenente;
        },

        fecharAtualizacao() {
            this.operacao = 'listar';
            this.despesa = null;
        },

        atualizarDespesa() {
            this.$store.dispatch('addRequest');
            let despesa = {
                idCnt: this.despesa.idCnt,
                convenioDespesaId: this.despesa.convenioDespesaId,
                convenioAcaoId: this.acao,
                percentualSebrae: this.percentualSebrae,
                percentualConvenente: this.percentualConvenente,
            };
            Services.atualizarDespesa(despesa).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Despesa',
                        detail: 'Atualizada com sucesso',
                        life: 3000,
                    });
                    this.despesa = null;
                    this.obterDespesas();
                    this.operacao = 'listar';
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Despesa',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        salvarAnexo(anexoDto) {
            this.$store.dispatch('addRequest');
            Services.inserirAnexoDespesa(anexoDto).then((response) => {
                this.$toast.add({
                    severity: response?.success ? 'success' : 'error',
                    summary: 'Anexo',
                    detail: response?.success ? 'Inserido com sucesso' : response.errors[0],
                    life: 3000,
                });

                if (response?.success) {
                    this.despesa = null;
                    this.obterDespesas();
                    this.operacao = 'listar';
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toggleCheckins() {
            if (this.despesasSelecionadas && this.despesasSelecionadas.length > 0) {
                this.despesasSelecionadas = [];
            } else {
                this.despesasSelecionadas = [
                    ...this.despesas.map((p) => {
                        return p.convenioDespesaId;
                    }),
                ];
            }
        },
    },

    watch: {
        convenio() {
            if (this.convenio) {
                this.obterDespesas();
            }
        },

        operacao() {
            if (this.operacao == 'vincular') this.obterAcoes();

            if (this.operacao == 'atualizar') {
                this.obterAcoes();
                this.obterAnexos();
            }
        },

        acao() {
            this.obterPercentuaisAcao();
        },
    },
};
</script>
