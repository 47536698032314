<template>
    <div class="grid">
        <div class="col-12" v-show="operacao == 'L'">
            <tabela :data="acoes" dataKey="convenioAcaoId" :globalFilterFields="['descricao']" stateKey="dt-rm-convenios-execucao-direta-acoes">
                <template #botoes>
                    <btn-inserir-acao @click="operacao = 'I'"></btn-inserir-acao>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex">
                                <btn-detalhar-acao @click="detalhar(slotProps.data)"></btn-detalhar-acao>
                                <btn-atualizar-acao @click="atualizar(slotProps.data)" class="ml-2"></btn-atualizar-acao>
                                <btn-excluir-acao
                                    :convenio="convenio"
                                    :acao="slotProps.data"
                                    :services="obterServices()"
                                    @obterAcoesAtualizadas="obterAcoes()"
                                    class="ml-2"
                                ></btn-excluir-acao>
                            </div>
                        </template>
                    </Column>
                    <Column field="descricao" header="Ação" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricao }}
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>TOTAIS</span>
                            </div>
                            <div class="white-space-nowrap text-right">
                                <span>PERCENTUAIS</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="valorFinanceiroConvenente" header="Financeiro Convenente" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorFinanceiroConvenente).format('$0,0.00') }}</span>
                            </div>
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.totalFinanceiroConvenente).format('$0,0.00') }}</span>
                            </div>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.percentualFinanceiroConvenente).format('0,0.00%') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="valorEconomicoConvenente" header="Econômico Convenente" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorEconomicoConvenente).format('$0,0.00') }}</span>
                            </div>
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.totalEconomicoConvenente).format('$0,0.00') }}</span>
                            </div>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.percentualEconomicoConvenente).format('0,0.00%') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="valorFinanceiroSebrae" header="Financeiro SEBRAE" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorFinanceiroSebrae).format('$0,0.00') }}</span>
                            </div>
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.totalFinanceiroSebrae).format('$0,0.00') }}</span>
                            </div>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.percentualFinanceiroSebrae).format('0,0.00%') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="valorEconomicoSebrae" header="Econômico SEBRAE" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorEconomicoSebrae).format('$0,0.00') }}</span>
                            </div>
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.totalEconomicoSebrae).format('$0,0.00') }}</span>
                            </div>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.percentualEconomicoSebrae).format('0,0.00%') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="totalFinanceiro" header="Total Financeiro" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.totalFinanceiro).format('$0,0.00') }}</span>
                            </div>
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.totalFinanceiro).format('$0,0.00') }}</span>
                            </div>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.percentualFinanceiro).format('0,0.00%') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="totalEconomico" header="Total Econômico" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.totalEconomico).format('$0,0.00') }}</span>
                            </div>
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.totalEconomico).format('$0,0.00') }}</span>
                            </div>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.percentualEconomico).format('0,0.00%') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="totalGeral" header="Total" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.totalGeral).format('$0,0.00') }}</span>
                            </div>
                        </template>
                        <template #footer>
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(totais?.totalGeral).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
        <div class="col-12" v-if="operacao != 'L' && operacao != 'D'">
            <acao-form
                :acao="acao"
                :convenio="convenio"
                :operacao="operacao"
                :services="obterServices()"
                @fechar="operacao = 'L'"
                @obterAcoesAtualizadas="obterAcoes()"
            ></acao-form>
        </div>
        <div class="col-12" v-if="operacao == 'D'">
            <acao-detalhar
                :acao="acao"
                :convenio="convenio"
                :services="obterServices()"
                @fechar="operacao = 'L'"
                @obterAcoesAtualizadas="obterAcoes()"
            ></acao-detalhar>
        </div>
    </div>
</template>

<script>
import BtnInserirAcao from './BtnInserirAcao';
import BtnExcluirAcao from './BtnExcluirAcao';
import BtnAtualizarAcao from './BtnAtualizarAcao';
import BtnDetalharAcao from './BtnDetalharAcao';
import AcaoDetalhar from './AcaoDetalhar';
import Services from './services';
import AcaoForm from './AcaoForm';

export default {
    emits: ['atualizar'],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        AcaoForm,
        BtnInserirAcao,
        BtnAtualizarAcao,
        BtnExcluirAcao,
        BtnDetalharAcao,
        AcaoDetalhar,
    },

    data() {
        return {
            totais: null,
            acoes: [],
            acao: null,
            operacao: 'L',
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterAcoes() {
            this.$store.dispatch('addRequest');
            Services.obterAcoes(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.totais = response.data;
                    this.acoes = [...response.data.acoes];
                } else {
                    this.totais = null;
                    this.acoes = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        detalhar(acao) {
            this.operacao = 'D';
            this.acao = acao;
        },

        atualizar(acao) {
            this.operacao = 'A';
            this.acao = acao;
        },
    },

    created() {
        this.obterAcoes();
    },
};
</script>