import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterRelatorio(idCnt, tipo) {
        return axiosJwt.get(`${urlBase}/${idCnt}/relatorios/${tipo}`, {
            responseType: 'blob',
        });
    },
};
