import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterConciliacao(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/prestacaocontas/conciliacao`);
    },

    obterTransacoesBancarias(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/transacoesbancarias`);
    },

    excluirTransacoesBancarias(idCnt, transacoesbancarias) {
        return axiosJwt.post(`${urlBase}/${idCnt}/transacoesbancarias/excluir`, transacoesbancarias);
    },

    obterTiposTransacoes(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/transacoesbancarias/tipostransacoes`);
    },

    atualizarTransacoesBancarias(idCnt, transacoesbancarias) {
        return axiosJwt.post(`${urlBase}/${idCnt}/transacoesbancarias/atualizar`, transacoesbancarias);
    },

    obterTransacoesBancariasDetalhesDisponiveis(idCnt, tipo) {
        return axiosJwt.get(`${urlBase}/${idCnt}/transacoesbancarias/detalhesdisponiveis?tipo=${tipo}`);
    },

    vincularTransacaoBancariaDetalhe(idCnt, vinculo) {
        return axiosJwt.post(`${urlBase}/${idCnt}/transacoesbancarias/vinculardetalhe`, vinculo);
    },

    desvincularTransacaoBancariaDetalhe(idCnt, convenioTransacaoBancariaId) {
        return axiosJwt.patch(`${urlBase}/${idCnt}/transacoesbancarias/desvinculardetalhe/${convenioTransacaoBancariaId}`);
    }
};
