<template>
    <div v-if="itemDetalhe">
        <detalhe titulo="Sequencial" :size="size">
            <span>{{ itemDetalhe.nSeqItmCnt }}</span>
        </detalhe>
        <detalhe titulo="Produto" :size="size">
            <span>({{ itemDetalhe.codigoPrd }}) {{ itemDetalhe.produto }}</span>
        </detalhe>
        <detalhe titulo="Status" :size="size">
            <span>({{ itemDetalhe.codStatus }}) {{ itemDetalhe.status }}</span>
        </detalhe>
        <detalhe titulo="Quantidade" :size="size">
            <span>{{ itemDetalhe.quantidade }}</span>
        </detalhe>
        <detalhe titulo="Preço Faturamento" :size="size">
            <span>{{ $numeral(itemDetalhe.precoFaturamento).format('$0,0.00') }}</span>
        </detalhe>
        <detalhe titulo="Tipo do Faturamento" :size="size">
            <span>{{ itemDetalhe.ePeriodicoDescr }}</span>
        </detalhe>
        <detalhe titulo="Tipo para Faturamento" :size="size">
            <span>({{ itemDetalhe.codTipoFaturamento }}) {{ itemDetalhe.tipoFaturamento }}</span>
        </detalhe>
        <!-- <detalhe titulo="Natureza Orçamentária" :size="size">
            <span
                >({{ itemDetalhe.codNaturezaOrcamentaria }})
                {{ itemDetalhe.naturezaOrcamentaria }}</span
            >
        </detalhe> -->
        <detalhe titulo="Tipo do Movimento" :size="size">
            <span>{{ itemDetalhe.codTipoMovimento }} - {{ itemDetalhe.tipoMovimento }}</span>
        </detalhe>
    </div>
    <Button icon="pi pi-arrow-circle-left" label="Retornar para listagem dos itens" class="mt-4 p-button-raised p-button-text" @click="fecharDetalhe()" />
    <aportes :item="itemDetalhe" @atualizar="obterItem()"></aportes>
    <item-aditivos :item="itemDetalhe"></item-aditivos>
</template>

<script>
import ConveniosServices from './services';
import ItemAditivos from './ItemAditivos';
import Aportes from './ItemAportes';

export default {
    props: {
        item: {
            type: Object,
        },
    },

    data() {
        return {
            size: '150',
            itemDetalhe: null,
        };
    },

    emits: ['fecharDetalhe'],

    components: {
        ItemAditivos,
        Aportes,
    },

    methods: {
        obterItem() {
            this.$store.dispatch('addRequest');
            ConveniosServices.obterItemPorId(this.$route.params.id, this.item.nSeqItmCnt).then((response) => {
                if (response?.success) {
                    this.itemDetalhe = response.data;
                } else {
                    this.itemDetalhe = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        fecharDetalhe() {
            this.$emit('fecharDetalhe');
        },
    },

    mounted() {
        this.obterItem();
    },
};
</script>
