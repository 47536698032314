import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterConvenios(filtro) {
        return axiosJwt.get(
            `${urlBase}?convenio=${filtro.convenio}&status=${filtro.status}&fornecedor=${filtro.fornecedor}&objeto=${filtro.objeto}&dataAssinaturaDe=${filtro.dataAssinaturaDe}&dataAssinaturaAte=${filtro.dataAssinaturaAte}`
        );
    },

    obterConveniosPorPerfilGestor(filtro) {
        return axiosJwt.get(
            `${urlBase}/gestor?convenio=${filtro.convenio}&status=${filtro.status}&fornecedor=${filtro.fornecedor}&objeto=${filtro.objeto}&dataAssinaturaDe=${filtro.dataAssinaturaDe}&dataAssinaturaAte=${filtro.dataAssinaturaAte}`
        );
    },

    obterFiltrosOpcoes() {
        return axiosJwt.get(`${urlBase}/filtrosopcoes`);
    },

    obterFiltrosOpcoesGestor() {
        return axiosJwt.get(`${urlBase}/filtrosopcoes/gestor`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },

    obterPorIdPerfilGestor(id) {
        return axiosJwt.get(`${urlBase}/${id}/gestor`);
    },

    inserirConvenioParceiro(convenio) {
        return axiosJwt.post(`${urlBase}/conveniosinserir`, convenio);
    },

    atualizarConvenioParceiro(convenio) {
        return axiosJwt.put(`${urlBase}/conveniosatualizar`, convenio);
    },
};
