<template>
    <div v-if="empresa">
        <div>
            <span
                >Empresa Beneficiada: <strong>{{ empresa.nome }} | {{ $toCpfCnpj(empresa.cpfCnpj) }}</strong></span
            >
        </div>
        <empresa-receitas :convenio="convenio" :empresa="empresa" :services="services"></empresa-receitas>
        <btn-voltar :back="voltarListaEmpresas"></btn-voltar>
    </div>
</template>

<script>
import EmpresaReceitas from './EmpresaReceitas';

export default {
    emits: ['resetEmpresa'],

    props: {
        convenio: {
            type: Object,
        },

        empresa: {
            type: Object,
        },

        services: {
            type: Object,
        },
    },

    components: {
        EmpresaReceitas,
    },

    methods: {
        voltarListaEmpresas() {
            this.$emit('resetEmpresa');
        },
    },
};
</script>