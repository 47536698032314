import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterReembolsos(idCnt, tipo) {
        return axiosJwt.get(`${urlBase}/${idCnt}/reembolsos/${tipo}`);
    },

    inserir(reembolso) {
        return axiosJwt.post(`${urlBase}/${reembolso.idCnt}/reembolsos`, reembolso);
    },

    atualizar(reembolso) {
        return axiosJwt.put(`${urlBase}/${reembolso.idCnt}/reembolsos`, reembolso);
    },

    excluir(idCnt, convenioReembolsoId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/reembolsos/${convenioReembolsoId}`);
    },

    vincularReembolsos(reembolsos) {
        return axiosJwt.post(`${urlBase}/${reembolsos.idCnt}/reembolsos/vincular`, reembolsos);
    },

    comprometer(reembolso){
        return axiosJwt.post(`${urlBase}/${reembolso.idCnt}/reembolsos/comprometer`, reembolso);
    },

    descomprometer(reembolso){
        return axiosJwt.patch(`${urlBase}/${reembolso.idCnt}/reembolsos/${reembolso.convenioReembolsoId}/descomprometer`);
    }

    // atualizarReceita(receita) {
    //     return axiosJwt.post(`${urlBase}/${receita.idCnt}/receitas/atualizar`, receita);
    // },

};
