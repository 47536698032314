<template>
    <div class="grid" v-if="conciliacao">
        <div class="col-12 md:col-6 lg:col-3" v-for="item in itens" :key="item.campo">
            <box-resultado
                :tipo="item.tipo"
                :titulo="item.titulo"
                :valor="conciliacao[item.campo]"
                :regra="item.regra"
                v-if="item.tipo == 'V' || item.tipo == 'V2'"
            ></box-resultado>
            <box-resultado :tipo="item.tipo" :titulo="item.titulo" :data="conciliacao[item.campo]" :regra="item.regra" v-if="item.tipo == 'D'"></box-resultado>
        </div>
    </div>
</template>

<script>
import Services from './services';
import BoxResultado from './BoxResultado.vue';

export default {
    emits: [],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        BoxResultado,
    },

    data() {
        return {
            conciliacao: null,
            itens: [
                { campo: 'saldoApurado', titulo: 'Saldo Apurado do Projeto', tipo: 'V', regra: 'Total das receitas menos o total das despesas do convênio' },
                {
                    campo: 'saldoContaConciliado',
                    titulo: 'Saldo em Conta Corrente',
                    tipo: 'V',
                    regra: 'Total das transações de crédito conciliadas menos o total das transações de débito conciliadas',
                },
                {
                    campo: 'resultadoConciliacao',
                    titulo: 'Resultado da Conciliação',
                    tipo: 'V',
                    regra: 'Saldo apurado do projeto menos o saldo em conta corrente',
                },
                { campo: 'dataUltimoExtrato', titulo: 'Data do Último Extrato', tipo: 'D', regra: 'Maior data fim dos extratos importados' },
                {
                    campo: 'tarifasBancariasApuradas',
                    titulo: 'Tarifas Bancárias Apuradas',
                    tipo: 'V2',
                    regra: 'Total de transações classificadas como "Tarifa Bancária"',
                },
                {
                    campo: 'tarifasBancariasRessarcidas',
                    titulo: 'Tarifas Bancárias Ressarcidas',
                    tipo: 'V2',
                    regra: 'Total de transações classificadas como "Tarifa Bancária Ressarcidas"',
                },
                {
                    campo: 'diferencaTarifasBancariasApuradas',
                    titulo: 'Diferença Tarifas Bancárias Apuradas',
                    tipo: 'V',
                    regra: 'Tarifas bancárias apuradas menos as tarifas bancárias ressarcidas',
                },
                {
                    campo: 'outrasDiferencasApuradas',
                    titulo: 'Outras Diferenças Apuradas',
                    tipo: 'V2',
                    regra: 'Total de transações classificadas como "Outros Créditos/Débitos"',
                },
                {
                    campo: 'despesasApuradas',
                    titulo: 'Despesas Apuradas',
                    tipo: 'V2',
                    regra: 'Total das despesas do convênio',
                },
                {
                    campo: 'despesasSacadas',
                    titulo: 'Despesas Sacadas',
                    tipo: 'V2',
                    regra: 'Total das transações de débito',
                },
                {
                    campo: 'reembolsosAoParceiro',
                    titulo: 'Reembolsos ao Parceiro',
                    tipo: 'V2',
                    regra: 'Total de transações classificadas como "Reembolso ao Parceiro"',
                },
                {
                    campo: 'reembolsosAoSebrae',
                    titulo: 'Reembolsos ao Sebrae',
                    tipo: 'V2',
                    regra: 'Total de transações classificadas como "Reembolso ao Sebrae"',
                },
                {
                    campo: 'creditosNaoConciliados',
                    titulo: 'Créditos Não Conciliados',
                    tipo: 'V2',
                    regra: 'Total de transações de crédito ainda não classificadas',
                },
                {
                    campo: 'debitosNaoConciliados',
                    titulo: 'Débitos Não Conciliados',
                    tipo: 'V2',
                    regra: 'Total de transações de débito ainda não classificadas',
                },
                {
                    campo: 'totalNaoConciliado',
                    titulo: 'Total Não Conciliado',
                    tipo: 'V2',
                    regra: 'Total de transações ainda não classificadas',
                },
            ],
        };
    },

    methods: {
        obterConciliacao() {
            this.$store.dispatch('addRequest');
            Services.obterConciliacao(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.conciliacao = response.data;
                } else {
                    this.conciliacao = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    watch: {
        convenio() {
            if (this.convenio) {
                this.obterConciliacao();
            }
        },
    },
};
</script>