<template>
    <div v-if="acao">
        <detalhe titulo="Ação" :size="size">
            <span>{{ acao.descricao }}</span>
        </detalhe>
        <detalhe titulo="Valor Financeiro Convenente" :size="size">
            <span>{{ $numeral(acao.valorFinanceiroConvenente).format('$0,0.00') }}</span>
        </detalhe>
        <detalhe titulo="Valor Econômico Convenente" :size="size">
            <span>{{ $numeral(acao.valorEconomicoConvenente).format('$0,0.00') }}</span>
        </detalhe>
        <detalhe titulo="Valor Financeiro SEBRAE" :size="size">
            <span>{{ $numeral(acao.valorFinanceiroSebrae).format('$0,0.00') }}</span>
        </detalhe>
        <detalhe titulo="Valor Econômico SEBRAE" :size="size">
            <span>{{ $numeral(acao.valorEconomicoSebrae).format('$0,0.00') }}</span>
        </detalhe>
        <detalhe titulo="Total Financeiro" :size="size">
            <span>{{ $numeral(acao.totalFinanceiro).format('$0,0.00') }}</span>
        </detalhe>
        <detalhe titulo="Total Econômico" :size="size">
            <span>{{ $numeral(acao.totalEconomico).format('$0,0.00') }}</span>
        </detalhe>
        <detalhe titulo="Total Geral" :size="size">
            <span>{{ $numeral(acao.totalGeral).format('$0,0.00') }}</span>
        </detalhe>
        <metas :convenio="convenio" :acao="acao" :services="services" @obterAcoesAtualizadas="$emit('obterAcoesAtualizadas')"
            ><btn-voltar :back="fechar" class="mt-2"></btn-voltar
        ></metas>
    </div>
</template>

<script>
import Metas from './Metas';

export default {
    emits: ['fechar'],

    data() {
        return {
            size: '180',
        };
    },

    props: {
        convenio: {
            type: Object,
        },

        acao: {
            type: Object,
        },

        services: {
            type: Object,
        },
    },

    components: {
        Metas,
    },

    methods: {
        fechar() {
            this.$emit('fechar');
        },
    },
};
</script>