<template>
    <Dialog v-model:visible="display" :modal="true" :style="{ width: '60vw' }" @hide="cancelar()" @show="preencher()" :contentStyle="{ overflow: 'visible' }">
        <template #header>
            <h5>{{ titulo }}</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-3">
                <label class="required">Valor</label>
                <InputNumber v-model="aporteTemp.valor" mode="currency" currency="BRL" locale="pt-BR" @input="v$.aporteTemp.valor.$touch()" />
                <small class="p-error" v-if="v$.aporteTemp.valor.$error">O valor é obrigatório</small>
            </div>
            <div class="field col-12 md:col-2">
                <label>Parcelado</label><br />
                <InputSwitch v-model="aporteTemp.parcelado" />
            </div>
            <div class="field col-12 md:col-3" v-if="aporteTemp.parcelado">
                <label class="required">Qtd Parcelas</label>
                <InputNumber v-model="aporteTemp.qtdParcelas" @input="v$.aporteTemp.qtdParcelas.$touch()" />
                <small class="p-error" v-if="v$.aporteTemp.qtdParcelas.$error">A quantidade de parcelas é obrigatória</small>
            </div>
            <div class="field col-12 md:col-3" v-if="aporteTemp.parcelado">
                <label class="required">Valor da Parcela</label>
                <InputNumber v-model="aporteTemp.valorParcela" mode="currency" currency="BRL" locale="pt-BR" @input="v$.aporteTemp.valorParcela.$touch()" />
                <small class="p-error" v-if="v$.aporteTemp.valorParcela.$error">O valor da parcela é obrigatório</small>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmarSalvar()" :disabled="naoPermiteSalvar" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        mostrar: {
            type: Boolean,
            default: false,
        },

        titulo: {
            type: String,
        },

        aporte: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        reset: {
            type: Boolean,
            default: false,
        },

        acao: {
            type: String,
            default: 'I',
        },
    },

    emits: ['cancelar', 'salvar', 'resetado'],

    data() {
        return {
            display: false,
            aporteTemp: {
                convenioItemAporteId: null,
                idCnt: null,
                nSeqItmCnt: null,
                tipoAporte: null,
                valor: null,
                parcelado: null,
                qtdParcelas: null,
                valorParcela: null,
            },
        };
    },

    validations() {
        return {
            aporteTemp: {
                convenioItemAporteId: { required },
                idCnt: { required },
                nSeqItmCnt: { required },
                tipoAporte: { required },
                valor: { required },
                parcelado: { required },
                qtdParcelas: { required },
                valorParcela: { required },
            },
        };
    },

    methods: {
        cancelar() {
            this.resetar();
            this.$emit('cancelar');
        },

        preencher() {
            if (this.acao === 'A') {
                this.aporteTemp = {
                    convenioItemAporteId: this.aporte.convenioItemAporteId,
                    idCnt: this.aporte.idCnt,
                    nSeqItmCnt: this.aporte.nSeqItmCnt,
                    tipoAporte: this.aporte.tipoAporte,
                    valor: this.aporte.valor,
                    parcelado: this.aporte.parcelado,
                    qtdParcelas: this.aporte.qtdParcelas,
                    valorParcela: this.aporte.valorParcela,
                };
            }
            this.v$.$touch();
        },

        resetar() {
            this.aporteTemp = {
                convenioItemAporteId: null,
                idCnt: null,
                nSeqItmCnt: null,
                tipoAporte: null,
                valor: null,
                parcelado: null,
                qtdParcelas: null,
                valorParcela: null,
            };
            this.$nextTick(() => {
                this.v$.$reset();
            });
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja atualizar o aporte?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$emit('salvar', this.aporteTemp);
        },
    },

    watch: {
        mostrar() {
            this.display = this.mostrar;
        },

        reset() {
            if (this.reset) {
                this.resetar();
                this.$emit('resetado');
            }
        },

        'aporteTemp.parcelado'() {
            if (!this.aporteTemp.parcelado) {
                this.aporteTemp.qtdParcelas = 0;
                this.aporteTemp.valorParcela = 0;
            }
        },
    },

    computed: {
        naoPermiteSalvar() {
            return this.v$.$invalid;
        },
    },
};
</script>
