<template>
    <erros-box :erros="erros"></erros-box>
    <div class="grid">
        <div class="col-12" v-show="operacao == 'listar'">
            <tabela
                :data="transacoes"
                dataKey="convenioTransacaoBancariaId"
                :globalFilterFields="['identificador', 'verificador', 'tipoTransacao', 'descricao', 'tipo', 'dataBr', 'valor', 'valorBr']"
                stateKey="dt-rm-ced-transacoes-bancarias"
            >
                <template #botoes>
                    <btn-atualizar-transacoes
                        :transacoesId="[...transacoesSelecionadas]"
                        @obterTransacoesAtualizadas="obterTransacoesAtualizadas"
                        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-11')"
                    ></btn-atualizar-transacoes>
                    <btn-excluir-transacoes
                        class="ml-2"
                        :transacoesId="[...transacoesSelecionadas]"
                        @obterTransacoesAtualizadas="obterTransacoesAtualizadas"
                        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-10')"
                    ></btn-excluir-transacoes>
                    <span class="ml-2 pt-2"
                        >SALDO:
                        <strong
                            ><span :class="{ 'text-primary': saldoPositivo, 'p-error': saldoNegativo }">{{ $numeral(saldo).format('$0,0.00') }}</span></strong
                        ></span
                    >
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #header>
                            <font-awesome-icon icon="check-square" @click="toggleCheckins()" size="lg" class="icon-button" title="Marcar/Desmarcar Todos" />
                        </template>
                        <template #body="slotProps">
                            <div class="flex flex-nowrap">
                                <Checkbox :value="slotProps.data.convenioTransacaoBancariaId" v-model="transacoesSelecionadas" v-if="!slotProps.data.detalhe" />
                                <span v-if="slotProps.data.tipoTransacao != 'A DEFINIR' && !slotProps.data.detalhe" class="ml-2">
                                    <btn-vincular-detalhe
                                        :transacao="slotProps.data"
                                        @obterTransacoesAtualizadas="obterTransacoesAtualizadas"
                                    ></btn-vincular-detalhe
                                ></span>
                                <span v-if="slotProps.data.tipoTransacao != 'A DEFINIR' && slotProps.data.detalhe" class="ml-2">
                                    <btn-desvincular-detalhe
                                        :transacao="slotProps.data"
                                        @obterTransacoesAtualizadas="obterTransacoesAtualizadas"
                                    ></btn-desvincular-detalhe
                                ></span>
                            </div>
                        </template>
                    </Column>
                    <Column field="identificador" header="Identificador" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.identificador }} </template>
                    </Column>
                    <Column field="verificador" header="Verificador" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.verificador }} </template>
                    </Column>
                    <Column field="tipoTransacao" header="Tipo da Transação" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.tipoTransacao }} </template>
                    </Column>
                    <Column field="descricao" header="Descrição" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricao }}<br /><span class="text-sm font-italic">{{ slotProps.data.detalhe }}</span>
                        </template>
                    </Column>
                    <Column field="tipo" header="Tipo" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.tipo }} </template>
                    </Column>
                    <Column field="data" header="Data" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.data, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="valor" header="Valor" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
    </div>
</template>

<script>
import Services from './services';
import BtnExcluirTransacoes from './BtnExcluirTransacoes.vue';
import BtnAtualizarTransacoes from './BtnAtualizarTransacoes.vue';
import BtnVincularDetalhe from './BtnVincularDetalhe.vue';
import BtnDesvincularDetalhe from './BtnDesvincularDetalhe.vue';

export default {
    emits: ['obterConciliacaoAtualizada'],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        BtnExcluirTransacoes,
        BtnAtualizarTransacoes,
        BtnVincularDetalhe,
        BtnDesvincularDetalhe,
    },

    data() {
        return {
            operacao: 'listar',
            transacoes: null,
            erros: [],
            saldo: 0,
            transacoesSelecionadas: [],
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterTransacoesBancarias() {
            this.$store.dispatch('addRequest');
            Services.obterTransacoesBancarias(this.$route.params.id)
                .then((response) => {
                    if (response?.success) {
                        this.transacoes = [...response.data.transacoes];
                        this.saldo = response.data.saldo;
                    } else {
                        this.transacoes = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterTransacoesAtualizadas() {
            this.transacoesSelecionadas = [];
            this.obterTransacoesBancarias();
            this.$emit('obterConciliacaoAtualizada');
        },

        toggleCheckins() {
            if (this.transacoesSelecionadas && this.transacoesSelecionadas.length > 0) {
                this.transacoesSelecionadas = [];
            } else {
                this.transacoesSelecionadas = [
                    ...this.transacoes.map((p) => {
                        return p.convenioTransacaoBancariaId;
                    }),
                ];
            }
        },
    },

    watch: {
        convenio() {
            if (this.convenio) {
                this.obterTransacoesBancarias();
            }
        },
    },

    computed: {
        saldoPositivo() {
            return this.saldo > 0;
        },

        saldoNegativo() {
            return this.saldo < 0;
        },
    },
};
</script>