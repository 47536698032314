<template>
    <painel :titulo="`Convênio de Execução Direta - ${convenio ? convenio.convenio : ''}`" icone="handshake" :refreshFunction="obterConvenio">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Cabeçalho">
                    <cabecalho :convenio="convenio" @atualizar="atualizarConvenioParceiro($event)"></cabecalho>
                </TabPanel>
                <TabPanel header="Condições Gerais">
                    <condicoes-gerais :convenio="convenio"></condicoes-gerais>
                </TabPanel>
                <TabPanel header="Campos Complementares">
                    <campos-complementares :convenio="convenio"></campos-complementares>
                </TabPanel>
                <TabPanel header="Itens">
                    <itens :convenio="convenio"></itens>
                </TabPanel>
                <TabPanel header="Plano de Trabalho">
                    <acoes :convenio="convenio" @atualizar="obterConvenio()"></acoes>
                </TabPanel>
                <TabPanel header="Receitas">
                    <receitas :convenio="convenio" @atualizar="obterConvenio()"></receitas>
                </TabPanel>
                <TabPanel header="Despesas">
                    <despesas :convenio="convenio" @atualizar="obterConvenio()"></despesas>
                </TabPanel>
                <TabPanel header="Reembolsos">
                    <reembolsos :convenio="convenio" @atualizar="obterConvenio()"></reembolsos>
                </TabPanel>
                <TabPanel header="Prestação de Contas">
                    <prestacao-contas :convenio="convenio" @atualizar="obterConvenio()"></prestacao-contas>
                </TabPanel>
                <TabPanel header="REB">
                    <rebs :convenio="convenio" @atualizar="obterConvenio()"></rebs>
                </TabPanel>
                <TabPanel header="Anexos">
                    <anexos :convenio="convenio" @atualizar="obterConvenio()"></anexos>
                </TabPanel>
                <TabPanel header="Relatórios" v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-08')">
                    <relatorios :convenio="convenio"></relatorios>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar @click="voltar()" v-if="activeIndex == 0"></btn-voltar>
    </painel>
</template>

<script>
import ConvenioService from './services';
import Cabecalho from './cabecalho/Cabecalho';
import CondicoesGerais from './ConvenioCondicoesGerais';
import CamposComplementares from './ConvenioCamposComplementares';
import Itens from './itens/Itens';
import Rebs from './empresasBeneficiadas/Rebs';
import Receitas from './receitas/Receitas';
import Despesas from './despesas/Despesas';
import Anexos from './anexos/Anexos';
import Acoes from './acoes/Acoes';
import Relatorios from './relatorios/Relatorios';
import PrestacaoContas from './prestacaocontas/PrestacaoContas';
import Reembolsos from './reembolsos/Reembolsos';

export default {
    components: {
        Cabecalho,
        CondicoesGerais,
        CamposComplementares,
        Itens,
        Receitas,
        Despesas,
        Rebs,
        Acoes,
        Anexos,
        Relatorios,
        PrestacaoContas,
        Reembolsos,
    },

    data() {
        return {
            convenio: null,
            activeIndex: 0,
        };
    },

    methods: {
        voltar() {
            if (this.$route.meta.rules[0] == 'RM-CONV-EXEC-DIRETA-GESTOR') {
                this.$router.push({
                    name: 'Rm_Convenios_ExecucaoDireta_gestor',
                });
                return;
            }
            this.$router.push({
                name: 'Rm_Convenios_ExecucaoDireta',
            });
        },

        obterConvenio() {
            this.$store.dispatch('addRequest');
            ConvenioService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.convenio = response.data;
                } else {
                    this.convenio = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterConveniosPerfilGestor() {
            this.$store.dispatch('addRequest');

            ConvenioService.obterPorIdPerfilGestor(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.convenio = response.data;
                } else {
                    this.convenio = null;
                }

                if (!this.convenio) {
                    this.$router.push({
                        name: 'Rm_Convenios_ExecucaoDireta_gestor',
                    });
                }

                this.$store.dispatch('removeRequest');
            });
        },
        atualizarConvenioParceiro(convenioParceiro) {
            this.convenio.convenioParceiro = convenioParceiro;
        },
    },

    mounted() {
        if (this.$route.meta.rules[0] == 'RM-CONV-EXEC-DIRETA-GESTOR') {
            this.obterConveniosPerfilGestor();
            return;
        }

        this.obterConvenio();
    },
};
</script>
