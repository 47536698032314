<template>
    <Dialog v-model:visible="display" :modal="true" :style="{ width: '60vw' }" @hide="cancelar()" :contentStyle="{ overflow: 'visible' }">
        <template #header>
            <h5>{{ titulo }}</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-3">
                <label>Tipo</label>
                <Dropdown v-model="tipo" :options="tipos" optionLabel="label" optionValue="value" />
            </div>
            <div class="field col-3" v-if="tipo == 'pf'">
                <label class="required">CPF</label>
                <div class="p-inputgroup">
                    <InputMask v-model="cpfCnpj" mask="999.999.999-99" :unmask="true" :disabled="empresa" />
                    <Button icon="pi pi-search" :disabled="!permitePesquisar" v-if="!empresa" @click="pesquisarCpfCnpj()" />
                    <Button icon="pi pi-refresh" class="p-button-warning" v-if="empresa" @click="resetar()" />
                </div>
            </div>
            <div class="field col-3" v-if="tipo == 'pj'">
                <label class="required">CNPJ</label>
                <div class="p-inputgroup">
                    <InputMask v-model="cpfCnpj" mask="99.999.999/9999-99" :unmask="true" :disabled="empresa" />
                    <Button icon="pi pi-search" :disabled="!permitePesquisar" v-if="!empresa" @click="pesquisarCpfCnpj()" />
                    <Button icon="pi pi-refresh" class="p-button-warning" v-if="empresa" @click="resetar()" />
                </div>
            </div>
            <div class="field col-6">
                <label>Nome</label>
                <InputText type="text" v-model="empresa" :disabled="true" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmarSalvar()" :disabled="naoPermiteSalvar" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        mostrar: {
            type: Boolean,
            default: false,
        },

        reset: {
            type: Boolean,
            default: false,
        },

        titulo: {
            type: String,
        },

        erros: {
            type: Array,
        },

        acao: {
            type: String,
            default: 'I',
        },

        services: {
            type: Object,
        },

        convenio: {
            type: Object,
        },
    },

    emits: ['cancelar', 'salvar', 'resetado'],

    data() {
        return {
            display: false,
            tipo: 'pj',
            tipos: [
                { label: 'PESSOA FÍSICA', value: 'pf' },
                { label: 'PESSOA JURÍDICA', value: 'pj' },
            ],
            cpfCnpj: null,
            empresa: null,
        };
    },

    validations() {
        return {
            cpfCnpj: { required },
            empresa: { required },
        };
    },

    methods: {
        cancelar() {
            this.resetar();
            this.$emit('cancelar');
        },

        resetar() {
            this.tipo = 'pj';
            this.cpfCnpj = null;
            this.empresa = null;
            this.$nextTick(() => {
                this.v$.$reset();
            });
        },

        pesquisarCpfCnpj() {
            this.$store.dispatch('addRequest');
            this.services.obterEmpresaPorCpfCnpj(this.convenio.idCnt, this.cpfCnpj).then((r) => {
                if (r) {
                    if (r.success) {
                        this.empresa = r.data.nome;
                    } else {
                        this.$emit('atualizarErros', r.errors);
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja prosseguir?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$emit('salvar', { cpfCnpj: this.cpfCnpj, empresa: this.empresa });
        },
    },

    watch: {
        mostrar() {
            this.display = this.mostrar;
        },

        reset() {
            if (this.reset) {
                this.resetar();
                this.$emit('resetado');
            }
        },

        tipo() {
            this.cpfCnpj = null;
            this.empresa = null;
        },
    },

    computed: {
        naoPermiteSalvar() {
            return this.v$.$invalid;
        },

        permitePesquisar() {
            if (this.cpfCnpj) {
                return this.cpfCnpj.length == 11 || this.cpfCnpj.length == 14;
            }
            return false;
        },
    },
};
</script>
