<template>
    <h5 class="heading-1 mt-4">
        <span>Receitas Vinculadas</span>
    </h5>
    <tabela
        :data="receitas"
        dataKey="convenioEmpresaBeneficiadaId"
        :globalFilterFields="['cpfCnpj', 'nome', 'situacao']"
        stateKey="dt-rm-convenios-execucao-direta-empresa-receitas"
    >
        <template #botoes>
            <!-- <btn-inserir-empresa :convenio="convenio" :services="obterServices()" @atualizar="obterEmpresas()"></btn-inserir-empresa> -->
        </template>
        <template #conteudo>
            <!-- <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <btn-detalhar-empresa @click="empresa = slotProps.data"></btn-detalhar-empresa>
                    <btn-excluir-empresa
                        :convenio="convenio"
                        :empresa="slotProps.data"
                        :services="obterServices()"
                        @obterEmpresasAtualizadas="obterEmpresas()"
                    ></btn-excluir-empresa>
                </template>
            </Column> -->
            <!-- <Column field="cnpjCpf" header="CNPJ/CPF" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.cpfCnpj }}
                </template>
            </Column>
            <Column field="nome" header="Empresa" :sortable="true">
                <template #body="slotProps"> {{ slotProps.data.nome }} </template>
            </Column> -->
            <Column field="idRM" header="IDRM" :sortable="true">
                <template #body="slotProps">
                    <div class="white-space-nowrap text-center">
                        <span>{{ slotProps.data.idRM }}</span>
                    </div>
                </template>
            </Column>
            <Column field="dataEmissao" header="Emissão" :sortable="true">
                <template #body="slotProps">
                    <div class="white-space-nowrap text-center">
                        <span>{{ slotProps.data.dataEmissao }}</span>
                    </div>
                </template>
            </Column>
            <Column field="historico" header="Histórico" :sortable="true">
                <template #body="slotProps">
                    <span>{{ slotProps.data.historico }}</span>
                </template>
            </Column>
            <Column field="valorPrevisto" header="Valor Previsto" :sortable="true">
                <template #body="slotProps">
                    <div class="white-space-nowrap text-right">
                        <span>{{ $numeral(slotProps.data.valorPrevisto).format('$0,0.00') }}</span>
                    </div>
                </template>
            </Column>
            <Column field="valorPago" header="Valor Pago" :sortable="true">
                <template #body="slotProps">
                    <div class="white-space-nowrap text-right">
                        <span>{{ $numeral(slotProps.data.valorPago).format('$0,0.00') }}</span>
                    </div>
                </template>
            </Column>
            <Column field="valorAReceber" header="Valor a Receber" :sortable="true">
                <template #body="slotProps">
                    <div class="white-space-nowrap text-right">
                        <span>{{ $numeral(slotProps.data.valorAReceber).format('$0,0.00') }}</span>
                    </div>
                </template>
            </Column>
            <Column field="valorDevolvido" header="Valor Devolvido" :sortable="true">
                <template #body="slotProps">
                    <div class="white-space-nowrap text-right">
                        <span>{{ $numeral(slotProps.data.valorDevolvido).format('$0,0.00') }}</span>
                    </div>
                </template>
            </Column>
            <Column field="situacao" header="Situação" :sortable="true">
                <template #body="slotProps"> {{ slotProps.data.situacao }} </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
export default {
    props: {
        convenio: {
            type: Object,
        },

        empresa: {
            type: Object,
        },

        services: {
            type: Object,
        },
    },

    data() {
        return {
            receitas: null,
        };
    },

    methods: {
        obterEmpresaReceitas() {
            this.$store.dispatch('addRequest');
            this.services.obterEmpresaReceitas(this.convenio.idCnt, this.empresa.cnpjCpf).then((response) => {
                if (response?.success) {
                    this.receitas = [...response.data];
                } else {
                    this.receitas = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterEmpresaReceitas();
    },
};
</script>