<template>
    <TabView v-model:activeIndex="activeIndex">
        <TabPanel header="Conciliação"><conciliacao :convenio="convenio" ref="conciliacao"></conciliacao></TabPanel>
        <TabPanel header="Transações Bancárias"
            ><transacoes-bancarias :convenio="convenio" ref="transacoes" @obterConciliacaoAtualizada="obterConciliacaoAtualizada()"></transacoes-bancarias
        ></TabPanel>
        <TabPanel header="Extratos Bancários"
            ><extratos-bancarios :convenio="convenio" @obterTransacoesAtualizadas="obterTransacoesAtualizadas()"></extratos-bancarios
        ></TabPanel>
    </TabView>
</template>

<script>
import Conciliacao from './Conciliacao';
import ExtratosBancarios from './extratosbancarios/ExtratosBancarios';
import TransacoesBancarias from './TransacoesBancarias';

export default {
    emits: ['atualizar'],

    data() {
        return {
            activeIndex: 0,
        };
    },

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        Conciliacao,
        ExtratosBancarios,
        TransacoesBancarias,
    },

    methods: {
        obterTransacoesAtualizadas() {
            this.$refs.transacoes.obterTransacoesBancarias();
            this.obterConciliacaoAtualizada();
        },

        obterConciliacaoAtualizada() {
            this.$refs.conciliacao.obterConciliacao();
        },
    },
};
</script>