<template>
    <font-awesome-icon
        icon="download"
        @click="download()"
        size="lg"
        class="icon-button ml-2 p-text-primary"
        title="Download"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-08')"
    />
</template>

<script>
import Service from './services';

export default {
    props: {
        convenio: { type: Object },
        relatorio: { type: Object },
    },

    methods: {
        download() {
            this.$store.dispatch('addRequest');
            Service.obterRelatorio(this.convenio.idCnt, this.relatorio.tipo).then((response) => {
                if (response?.success) {
                    this.$download(response.data, `${this.relatorio.arquivo}_${this.convenio.convenio}.${this.relatorio.tipoArquivo}`);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Relatórios',
                        detail: 'Erro ao realizar download do relatório',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
