import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    obterDespesas(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/despesas`);
    },

    obterAcoes(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/despesas/acoes`);
    },

    obterPercentuaisAcao(idCnt, acao) {
        return axiosJwt.get(`${urlBase}/${idCnt}/despesas/acoes/${acao}/percentuais`);
    },

    obterAnexosDespesa(idCnt, convenioDespesaId) {
        return axiosJwt.get(`${urlBase}/${idCnt}/despesas/${convenioDespesaId}/anexosdespesas`);
    },

    vincularDespesas(despesas) {
        return axiosJwt.post(`${urlBase}/${despesas.idCnt}/despesas/vincular`, despesas);
    },

    atualizarDespesa(despesa) {
        return axiosJwt.post(`${urlBase}/${despesa.idCnt}/despesas/atualizar`, despesa);
    },

    excluirDespesa(idCnt, convenioDespesaId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/despesas/${convenioDespesaId}`);
    },

    excluirAnexoDespesa(idCnt, convenioAnexoId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/despesas/anexosdespesas/${convenioAnexoId}`);
    },

    reconhecerReceitas(idCnt, reconhecimentoDeDespesaDto) {
        return axiosJwt.post(`${urlBase}/${idCnt}/despesas/reconhecerreceitas`, reconhecimentoDeDespesaDto);
    },

    inserirAnexoDespesa(anexoDto) {
        const formData = new FormData();
        formData.append('idCnt', anexoDto.idCnt);
        formData.append('convenioDespesaId', anexoDto.convenioDespesaId);
        formData.append('arquivo', anexoDto.arquivo);
        formData.append('tipo', anexoDto.tipo);
        return axiosJwt.post(`${urlBase}/${anexoDto.idCnt}/despesas/anexosdespesas`, formData, formDataType);
    },
};
