import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterEmpresaPorCpfCnpj(idCnt, cpfCnpj) {
        return axiosJwt.get(`${urlBase}/${idCnt}/empresas/pesquisar/${cpfCnpj}`);
    },

    obterEmpresasBeneficiadas(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/empresas`);
    },

    inserirEmpresa(empresa) {
        return axiosJwt.post(`${urlBase}/${empresa.idCnt}/empresas/inserir`, empresa);
    },

    excluirEmpresa(idCnt, empresaId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/empresas/${empresaId}`);
    },

    obterEmpresaReceitas(idCnt, cpfCnpj) {
        return axiosJwt.get(`${urlBase}/${idCnt}/empresas/${cpfCnpj}/receitas`);
    },
};
