<template>
    <div class="grid">
        <div class="col-12" v-show="!empresa">
            <tabela
                :data="empresas"
                dataKey="convenioEmpresaBeneficiadaId"
                :globalFilterFields="['cpfCnpj', 'nome', 'situacao']"
                stateKey="dt-rm-convenios-execucao-direta-empresas"
            >
                <template #botoes>
                    <btn-inserir-empresa :convenio="convenio" :services="obterServices()" @atualizar="obterEmpresas()"></btn-inserir-empresa>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex">
                                <btn-detalhar-empresa @click="empresa = slotProps.data"></btn-detalhar-empresa>
                                <btn-excluir-empresa
                                    :convenio="convenio"
                                    :empresa="slotProps.data"
                                    :services="obterServices()"
                                    @obterEmpresasAtualizadas="obterEmpresas()"
                                    class="ml-2"
                                ></btn-excluir-empresa>
                            </div>
                        </template>
                    </Column>
                    <Column field="cnpjCpf" header="CNPJ/CPF" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.cpfCnpj }}
                        </template>
                    </Column>
                    <Column field="nome" header="Empresa" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.nome }} </template>
                    </Column>
                    <Column field="valorPrevisto" header="Valor Previsto" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorPrevisto).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="valorPago" header="Valor Pago" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorPago).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="valorAReceber" header="Valor a Receber" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorAReceber).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="valorDevolvido" header="Valor Devolvido" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorDevolvido).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="situacao" header="Situação" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.situacao }} </template>
                    </Column>
                </template>
            </tabela>
        </div>
        <div class="col-12" v-if="empresa">
            <empresa-detalhar :empresa="empresa" :convenio="convenio" :services="obterServices()" @resetEmpresa="empresa = null"></empresa-detalhar>
        </div>
    </div>
</template>

<script>
import BtnInserirEmpresa from './BtnInserirEmpresa';
import BtnExcluirEmpresa from './BtnExcluirEmpresa';
import BtnDetalharEmpresa from './BtnDetalharEmpresa';
import EmpresaDetalhar from './EmpresaDetalhar';
import Services from './services';

export default {
    emits: ['atualizar'],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        BtnInserirEmpresa,
        BtnExcluirEmpresa,
        BtnDetalharEmpresa,
        EmpresaDetalhar,
    },

    data() {
        return {
            empresas: [],
            empresa: null,
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterEmpresas() {
            this.$store.dispatch('addRequest');
            Services.obterEmpresasBeneficiadas(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.empresas = [...response.data];
                } else {
                    this.empresas = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    watch: {
        convenio() {
            if (this.convenio && this.convenio.empresasBeneficiadas) {
                this.empresas = [...this.convenio.empresasBeneficiadas];
            }
        },
    },
};
</script>