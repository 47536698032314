<template>
    <detalhe titulo="Início Vigência" :size="size">
        {{ $dateFormat(convenio?.dataInicio, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Final Vigência" :size="size">
        {{ $dateFormat(convenio?.dataFim, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Data Assinatura" :size="size">
        {{ $dateFormat(convenio?.dataContrato, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Data Cancelamento" :size="size">
        {{ $dateFormat(convenio?.DataCancelamento, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Data Encerramento" :size="size">
        {{ $dateFormat(convenio?.dataEncerramento, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Data Aceite PC" :size="size">
        {{ $dateFormat(convenio?.dataAceitePC, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Conta Caixa" :size="size">
        <span v-if="convenio">({{ convenio.codContaCaixa }}) {{ convenio.contaCaixa }}</span>
    </detalhe>
    <detalhe titulo="Conta de Rateio Gerencial" :size="size">
        <span v-if="convenio?.codContaGerencial">({{ convenio.codContaGerencial }}) {{ convenio.contaGerencial }}</span>
    </detalhe>
    <detalhe titulo="Histórico" :size="size">
        {{ convenio?.historico }}
    </detalhe>
</template>

<script>
export default {
    props: {
        convenio: {
            type: Object,
        },
    },

    data() {
        return {
            size: '160',
        };
    },
};
</script>
