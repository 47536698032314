<template>
    <div class="grid mt-2" v-if="item?.aportes">
        <div class="col-12">
            <h5 class="heading-1">
                <span>APORTES</span>
            </h5>
            <tabela
                :data="item?.aportes"
                dataKey="tipoAporte"
                stateKey="dt-rm-convenios-execucao-direta-aportes"
                :mostrarPesquisa="false"
                :mostrarHeader="false"
                :mostrarPaginacao="false"
            >
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <btn-atualizar-aporte
                                :aporte="slotProps.data"
                                @atualizar="$emit('atualizar')"
                                v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-02')"
                            ></btn-atualizar-aporte>
                        </template>
                    </Column>
                    <Column field="tipoAporteDescr" header="Tipo" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.tipoAporteDescr }}
                        </template>
                    </Column>
                    <Column field="valor" header="Valor" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="percentual" header="%" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.percentual).format('0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="parcelado" header="Parcelado" :sortable="true">
                        <template #body="slotProps">
                            <status :status="slotProps.data.parcelado ? 'SIM' : 'NÃO'"></status>
                        </template>
                    </Column>
                    <Column field="qtdParcelas" header="Qtd Parcelas" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.qtdParcelas }}
                        </template>
                    </Column>
                    <Column field="valorParcela" header="Valor Parcela" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valorParcela).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
    </div>
    <div v-if="item">
        <detalhe titulo="Total de Aportes" size="110">
            <span>{{ $numeral(item?.totalAportes).format('$0,0.00') }} ({{ $numeral(item?.percentualTotalAportes).format('0,0.00') }}%)</span>
        </detalhe>
    </div>
</template>

<script>
import BtnAtualizarAporte from './BtnAtualizarAporte';

export default {
    props: {
        item: {
            type: Object,
        },
    },

    components: {
        BtnAtualizarAporte,
    },

    data() {
        return {};
    },

    methods: {},
};
</script>
