<template>
    <font-awesome-icon
        icon="download"
        @click="download()"
        size="lg"
        class="icon-button ml-2 p-text-primary"
        title="Download"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-01')"
    />
</template>

<script>
import Service from './services';

export default {
    props: {
        convenio: {
            type: Object,
        },

        anexo: {
            type: Object,
        },
    },

    methods: {
        download() {
            this.$store.dispatch('addRequest');
            Service.obterAnexo(this.convenio.idCnt, this.anexo.convenioAnexoId).then((response) => {
                if (response?.success) {
                    this.$download(response.data, this.anexo.nomeArquivo);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Anexo',
                        detail: 'Erro ao realizar download',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
