<template>
    <font-awesome-icon
        icon="trash"
        @click="confirmar()"
        size="lg"
        class="icon-button p-text-danger"
        title="Excluir"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-04')"
    />
</template>

<script>
export default {
    props: {
        services: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        receita: {
            type: Object,
        },
    },

    emits: ['obterReceitasAtualizadas'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir a receita?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            this.services.excluirReceita(this.convenio.idCnt, this.receita.convenioReceitaId).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Receitas',
                        detail: 'Receita excluída com sucesso',
                        life: 3000,
                    });
                    this.$emit('obterReceitasAtualizadas');
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Receitas',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
