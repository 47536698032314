<template>
    <div class="grid">
        <div class="col-12" v-show="operacao == 'listar'">
            <Message :closable="false"
                >TOTAL RECEITAS: <strong>{{ $numeral(valorTotal).format('$0,0.00') }}</strong></Message
            >
            <tabela
                :data="receitas"
                dataKey="convenioReceitaId"
                :globalFilterFields="['fonte', 'descricao', 'idMov']"
                stateKey="dt-rm-convenios-execucao-direta-receitas"
            >
                <template #botoes>
                    <btn-reconhecer-receitas
                        :receitasId="[...receitasSelecionadas]"
                        @obterReceitasAtualizadas="obterReceitas()"
                        :convenio="convenio"
                        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-04') && convenio?.temContaGerencial"
                    />
                    <btn-vincular-receita @click="operacao = 'vincular'" class="ml-2"></btn-vincular-receita>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #header>
                            <font-awesome-icon icon="check-square" @click="toggleCheckins()" size="lg" class="icon-button" title="Marcar/Desmarcar Todos" />
                        </template>
                        <template #body="slotProps">
                            <div class="flex flex-nowrap">
                                <Checkbox
                                    :value="slotProps.data.convenioReceitaId"
                                    v-model="receitasSelecionadas"
                                    v-if="!slotProps.data.idReconhecimentoReceita"
                                    class="mr-2"
                                />
                                <btn-atualizar-receita @click="abrirAtualizacao(slotProps.data)"></btn-atualizar-receita>
                                <btn-excluir-receita
                                    :convenio="convenio"
                                    :receita="slotProps.data"
                                    :services="obterServices()"
                                    @obterReceitasAtualizadas="obterReceitas()"
                                    class="ml-1"
                                ></btn-excluir-receita>
                            </div>
                        </template>
                    </Column>
                    <Column field="fonte" header="Fonte" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.fonte }}
                        </template>
                    </Column>
                    <Column field="descricao" header="Descrição" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricao }}
                        </template>
                    </Column>
                    <Column field="dataReceita" header="Data Receita" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.dataReceita, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="idMov" header="ID RM" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.idMov }} </template>
                    </Column>
                    <Column field="valor" header="Valor" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="idReconhecimentoReceita" header="Rec. de receita" :sortable="true">
                        <template #body="slotProps">
                            <div class="text-right">
                                <span v-if="slotProps.data.idReconhecimentoReceita"
                                    ><small
                                        ><strong>({{ slotProps.data.idReconhecimentoReceita }})</strong></small
                                    ></span
                                >
                                <span v-if="!slotProps.data.idReconhecimentoReceita"
                                    ><small><strong>Reconhecimento de receita não realizada</strong></small></span
                                >
                            </div>
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
        <div class="col-12" v-if="operacao == 'vincular'">
            <pesquisar-movimentos @fechar="operacao = 'listar'" @selecionar="confirmarVincular($event)" :convenio="convenio" tipo="R">
                <h5 class="heading-1">
                    <span>Vincular Receitas</span>
                </h5>
                <div class="formgrid grid p-fluid">
                    <div class="field col-12 md:col-6">
                        <label>Fonte</label>
                        <Dropdown v-model="fonte" :options="fontes" optionLabel="nome" optionValue="convenioReceitaFonteId" />
                    </div>
                </div>
            </pesquisar-movimentos>
        </div>
        <div class="col-12" v-if="operacao == 'atualizar' && receita">
            <atualizar-form :receita="receita" @fechar="operacao = 'listar'" @salvar="atualizarReceita()">
                <div class="formgrid grid p-fluid">
                    <div class="field col-12 md:col-6">
                        <label>Fonte</label>
                        <Dropdown v-model="fonte" :options="fontes" optionLabel="nome" optionValue="convenioReceitaFonteId" />
                    </div>
                </div>
            </atualizar-form>
        </div>
    </div>
</template>

<script>
import BtnReconhecerReceitas from './BtnReconhecerReceitas.vue';
import BtnVincularReceita from './BtnVincularReceita';
import PesquisarMovimentos from '../movimentos/PesquisarMovimentos';
import BtnExcluirReceita from './BtnExcluirReceita';
import BtnAtualizarReceita from './BtnAtualizarReceita';
import AtualizarForm from './AtualizarForm';
import Services from './services';

export default {
    emits: ['atualizar'],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        BtnReconhecerReceitas,
        BtnVincularReceita,
        BtnExcluirReceita,
        PesquisarMovimentos,
        BtnAtualizarReceita,
        AtualizarForm,
    },

    data() {
        return {
            operacao: 'listar',
            receitas: null,
            valorTotal: 0,
            fonte: null,
            fontes: null,
            receita: null,
            receitasSelecionadas: [],
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterReceitas() {
            this.$store.dispatch('addRequest');
            Services.obterReceitas(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.receitas = [...response.data.receitas];
                    this.valorTotal = response.data.valorTotal;
                } else {
                    this.receitas = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterTiposFontes() {
            this.$store.dispatch('addRequest');
            Services.obterTiposFontes(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.fontes = [...response.data];
                    this.fonte = this.fontes[0].convenioReceitaFonteId;
                    if (this.receita) {
                        this.fonte = this.receita.convenioReceitaFonteId;
                    }
                } else {
                    this.fontes = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        confirmarVincular(idsRm) {
            this.$confirm.require({
                message: `Tem certeza que deseja vincular os movimentos selecionados`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.vincular(idsRm);
                },
            });
        },

        vincular(idsRm) {
            this.$store.dispatch('addRequest');
            let receitas = {
                idCnt: this.convenio.idCnt,
                fonte: this.fonte,
                idsRm: idsRm,
            };
            Services.vincularReceitas(receitas).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Receitas',
                        detail: 'Vinculos realizados com sucesso',
                        life: 3000,
                    });
                    this.operacao = 'listar';
                    this.obterReceitas();
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Receitas',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        abrirAtualizacao(receita) {
            this.receita = receita;
            this.operacao = 'atualizar';
            this.fonte = receita?.convenioReceitaFonteId;
        },

        atualizarReceita() {
            this.$store.dispatch('addRequest');
            let receita = {
                idCnt: this.receita.idCnt,
                convenioReceitaId: this.receita.convenioReceitaId,
                fonte: this.fonte,
            };
            Services.atualizarReceita(receita).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Receita',
                        detail: 'Atualizada com sucesso',
                        life: 3000,
                    });
                    this.receita = null;
                    this.obterReceitas();
                    this.operacao = 'listar';
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Receitas',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
        toggleCheckins() {
            if (this.receitasSelecionadas && this.receitasSelecionadas.length > 0) {
                this.receitasSelecionadas = [];
            } else {
                this.receitasSelecionadas = [
                    ...this.receitas.map((p) => {
                        return p.convenioReceitaId;
                    }),
                ];
            }
        },
    },

    watch: {
        convenio() {
            if (this.convenio) {
                this.obterReceitas();
            }
        },

        operacao() {
            if (this.operacao == 'vincular' || this.operacao == 'atualizar') {
                this.obterTiposFontes();
            }
        },
    },
};
</script>
