<template>
    <font-awesome-icon
        :icon="icon"
        @click="abrir()"
        size="lg"
        class="icon-button p-text-primary"
        title="Atualizar"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-17')"
    />
    <convenio-parceiro-form
        :mostrar="mostrar"
        :convenioParceiro="convenioParceiro"
        @cancelar="fechar()"
        @salvar="($event) => (convenio.convenioParceiro ? atualizarConvenioParceiro($event) : inserirConvenioParceiro($event))"
        :erros="erros"
    ></convenio-parceiro-form>
</template>

<script>
import ConvenioParceiroForm from './ConvenioParceiroForm';
import { StatusIconeConvenioParceiro } from './CabecalhoEnum';
import ConveniosServices from '../services';

export default {
    components: { ConvenioParceiroForm },
    props: {
        convenio: { type: Object },
    },
    emits: ['atualizar'],
    data() {
        return {
            mostrar: false,
            erros: [],
        };
    },
    computed: {
        icon() {
            return this.convenio?.convenioParceiro ? StatusIconeConvenioParceiro.ATUALIZAR : StatusIconeConvenioParceiro.INSERIR;
        },
        convenioParceiro() {
            return this.convenio?.convenioParceiro;
        },
    },
    methods: {
        abrir() {
            this.mostrar = true;
        },
        fechar() {
            if (this.mostrar) this.mostrar = false;

            this.erros = [];
        },
        inserirConvenioParceiro(data) {
            this.$store.dispatch('addRequest');
            const convenioParceiroDto = {
                convenioParceiro: data,
                idCnt: this.convenio.idCnt,
            };

            ConveniosServices.inserirConvenioParceiro(convenioParceiroDto).then((resultado) => {
                this.$store.dispatch('removeRequest');

                if (!resultado) return;

                this.retorno(resultado, 'Inserido', convenioParceiroDto.convenioParceiro);
            });
        },
        atualizarConvenioParceiro(data) {
            this.$store.dispatch('addRequest');
            const convenioParceiroDto = {
                convenioParceiro: data,
                idCnt: this.convenio.idCnt,
            };

            ConveniosServices.atualizarConvenioParceiro(convenioParceiroDto).then((resultado) => {
                this.$store.dispatch('removeRequest');

                if (!resultado) return;

                this.retorno(resultado, 'Atualizado', convenioParceiroDto.convenioParceiro);
            });
        },
        retorno(resultado, status, convenioParceiro) {
            if (!resultado.success) {
                this.erros = resultado.errors;
                return;
            }

            this.atualizado = true;
            this.$toast.add({
                severity: 'success',
                summary: 'Convênio',
                detail: `${status} com sucesso`,
                life: 3000,
            });

            this.$emit('atualizar', convenioParceiro);

            this.fechar();
        },
    },
};
</script>
