<template>
    <slot></slot>
    <h5 class="heading-1">
        <span>Inserir Extrato</span>
    </h5>
    <div class="formgrid grid p-fluid">
        <div class="field col-12">
            <label>Arquivo</label>
            <FileUpload
                :customUpload="true"
                @uploader="arquivoSelecionado"
                mode="basic"
                chooseLabel="Selecionar Arquivo"
                :auto="true"
                v-if="!arquivo"
                accept=".ofx"
            />
            <div v-if="arquivo">
                <strong>{{ nomeArquivo }}</strong>
                <i class="pi pi-trash p-error icon-button ml-2" style="fontsize: 1.2rem" @click="limparArquivo()"></i>
            </div>
        </div>
    </div>
    <slot name="botoes"></slot>
    <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar()" />
    <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
</template>

<script>
import useVuelidate from '@vuelidate/core';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    emits: ['salvar', 'fechar'],

    data() {
        return {
            arquivo: null,
            nomeArquivo: null,
            arquivoAlterado: false,
        };
    },

    validations() {
        return {
            arquivo: {
                conteudoOK: () => {
                    return this.conteudoOK;
                },
            },
        };
    },

    methods: {
        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja inserir o extrato?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let extrato = {
                arquivo: this.arquivo,
            };
            this.$emit('salvar', extrato);
        },

        arquivoSelecionado(event) {
            this.arquivo = event.files[0];
            this.nomeArquivo = this.arquivo.name;
        },

        limparArquivo() {
            this.arquivo = null;
            this.arquivoAlterado = true;
        },

        cancelar() {
            this.$emit('fechar');
        },
    },

    computed: {
        conteudoOK() {
            return this.arquivo !== null;
        },
    },
};
</script>
