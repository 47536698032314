<template>
    <font-awesome-icon
        icon="dollar-sign"
        @click="confirmar()"
        size="lg"
        class="icon-button p-text-success"
        title="Comprometer"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-13')"
    />
</template>

<script>
export default {
    props: {
        services: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        reembolso: {
            type: Object,
        },
    },

    emits: ['obterReembolsosAtualizados', 'informarErro'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja comprometer o reembolso?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.comprometer();
                },
            });
        },

        comprometer() {
            this.$store.dispatch('addRequest');
            this.$emit('informarErro', []);
            this.services
                .comprometer(this.reembolso)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Comprometimento',
                            detail: 'Comprometimento realizado com sucesso',
                            life: 3000,
                        });
                        this.$emit('obterReembolsosAtualizados');
                    } else {
                        this.$emit('informarErro', response.errors);
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>
