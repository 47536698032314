import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    obterExtratosBancarios(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/extratosbancarios`);
    },

    excluirExtratoBancario(idCnt, extratoBancarioId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/extratosbancarios/${extratoBancarioId}`);
    },

    inserirExtratoBancario(extrato) {
        const formData = new FormData();
        formData.append('idCnt', extrato.idCnt);
        formData.append('arquivo', extrato.arquivo);
        return axiosJwt.post(`${urlBase}/${extrato.idCnt}/extratosbancarios`, formData, formDataType);
    }
};