<template>
    <slot></slot>
    <h5 class="heading-1">
        <span>{{ operacao == 'I' ? 'INSERIR' : 'ATUALIZAR' }} REEMBOLSO</span>
    </h5>
    <erros-box :erros="erros"></erros-box>
    <div class="formgrid grid p-fluid">
        <div class="field col-12 md:col-3 lg:col-2">
            <label class="required">Data de Emissão</label>
            <Calendar v-model="dataEmissao" :manualInput="false" />
            <small class="p-error" v-if="v$.dataEmissao.$error">A data de emissão é obrigatória</small>
        </div>
        <div class="field col-12 md:col-3 lg:col-2">
            <label class="required">Data do Movimento</label>
            <Calendar v-model="dataMovimento" :manualInput="false" />
            <small class="p-error" v-if="v$.dataMovimento.$error">A data do movimento é obrigatória</small>
        </div>
        <div class="field col-12 md:col-3">
            <label class="required">Valor</label>
            <InputNumber v-model="valor" mode="currency" currency="BRL" locale="pt-BR" @input="v$.valor.$touch()" />
            <small class="p-error" v-if="v$.valor.$error">O valor é obrigatório</small>
        </div>
        <div class="field col-12">
            <label class="required">Histórico</label>
            <Textarea rows="3" v-model="descricao" @input="v$.descricao.$touch()" maxlength="250" />
            <small class="p-error" v-if="v$.descricao.$error">O histórico é obrigatório</small>
        </div>
    </div>
    <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar()" />
    <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';

export default {
    props: {
        operacao: {
            type: String,
        },

        reembolso: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        services: {
            type: Object,
        },

        tipo: {
            type: String,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    emits: ['obterReembolsosAtualizados', 'fechar'],

    data() {
        return {
            dataEmissao: new Date(),
            dataMovimento: new Date(),
            descricao: null,
            valor: 0,
            erros: [],
        };
    },

    validations() {
        return {
            dataEmissao: { required },
            dataMovimento: { required },
            descricao: { required },
            valor: { required, minValue: minValue(0.01) },
        };
    },

    methods: {
        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.operacao == 'I' ? 'inserir' : 'atualizar'} o reembolso`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            let reembolsoDto = {
                idCnt: this.convenio.idCnt,
                descricao: this.descricao,
                dataEmissao: this.$moment(this.dataEmissao).format('YYYY-MM-DD'),
                dataMovimento: this.$moment(this.dataMovimento).format('YYYY-MM-DD'),
                valor: this.valor,
                tipo: this.tipo == 'SEBRAE' ? 0 : 1,
            };

            let promisse;
            if (this.operacao == 'A') {
                reembolsoDto.convenioReembolsoId = this.reembolso.convenioReembolsoId;
                promisse = this.services.atualizar(reembolsoDto);
            } else {
                promisse = this.services.inserir(reembolsoDto);
            }
            promisse
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Reembolsos',
                            detail: `Reembolso ${this.operacao == 'I' ? 'inserido' : 'atualizado'} com sucesso`,
                            life: 3000,
                        });
                        this.$emit('fechar', true);
                    } else {
                        this.erros = response.errors;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        cancelar() {
            this.$emit('fechar');
        },

        preencherForm() {
            this.descricao = this.reembolso.descricao;
            this.dataEmissao = this.$moment(this.reembolso.dataEmissao).toDate();
            this.dataMovimento = this.$moment(this.reembolso.dataMovimento).toDate();
            this.valor = this.reembolso.valor;
        },
    },

    created() {
        if (this.operacao == 'A') {
            this.preencherForm();
        }
    },
};
</script>
