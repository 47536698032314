<template>
    <Button
        :label="`Reconhecer Receita (${despesasId?.length ?? 0})`"
        icon="pi pi-pencil"
        iconPos="left"
        class="p-button"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-05')"
        @click="abrir()"
        :disabled="!despesasId || despesasId.length == 0"
    />
    <reconhecer-receita-form :mostrar="mostrar" :convenio="convenio" :erros="erros" @cancelar="fechar()" @salvar="atualizar($event)"></reconhecer-receita-form>
</template>

<script>
import Services from './services';
import ReconhecerReceitaForm from './ReconhecerReceitaForm';

export default {
    emits: ['obterDespesasAtualizadas'],

    components: { ReconhecerReceitaForm },

    props: {
        despesasId: { type: Array },
        convenio: { type: Object },
    },

    data() {
        return {
            mostrar: false,
            erros: [],
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },
        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            this.erros = [];
        },
        atualizar(reconhecimentoDeDespesa) {
            this.$store.dispatch('addRequest');

            const reconhecimentoDeDespesaDto = {
                despesasId: [...this.despesasId],
                dataDaContabilizacao: reconhecimentoDeDespesa.dataDaContabilizacao,
                historico: reconhecimentoDeDespesa.historico,
            };

            Services.reconhecerReceitas(this.$route.params.id, reconhecimentoDeDespesaDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({ severity: 'success', summary: 'Despesas', detail: 'Atualizadas com sucesso', life: 3000 });
                    this.$emit('obterDespesasAtualizadas');
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Despesas', detail: response.errors[0], life: 3000 });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
