<template>
    <slot></slot>
    <h5 class="heading-1">
        <span>{{ operacao == 'I' ? 'INSERIR' : 'ATUALIZAR' }} META</span>
    </h5>
    <erros-box :erros="erros"></erros-box>
    <div class="formgrid grid p-fluid">
        <div class="field col-12 md:col-12">
            <label class="required">Meta</label>
            <InputText type="text" v-model="descricao" @input="v$.descricao.$touch()" />
            <small class="p-error" v-if="v$.descricao.$error">A descrição da ação é obrigatória</small>
        </div>
        <div class="field col-12 md:col-12">
            <label class="required">Aplicação da Meta</label>
            <InputText type="text" v-model="aplicacaoMeta" @input="v$.aplicacaoMeta.$touch()" />
            <small class="p-error" v-if="v$.aplicacaoMeta.$error">A aplicação da meta é obrigatória</small>
        </div>
        <div class="field col-12 md:col-6">
            <label class="required">Tipo</label>
            <Dropdown v-model="tipo" :options="tipos" optionValue="convenioAcaoMetaTipoId" optionLabel="descricao" />
        </div>
        <div class="field col-12 md:col-6">
            <label class="required">Quantidade</label>
            <InputNumber v-model="quantidade" mode="decimal" :minFractionDigits="2" locale="pt-BR" @input="v$.quantidade.$touch()" />
            <small class="p-error" v-if="v$.quantidade.$error">A quantidade é obrigatória</small>
        </div>
    </div>
    <slot name="botoes"></slot>
    <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar()" />
    <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        operacao: {
            type: String,
        },

        acao: {
            type: Object,
        },

        meta: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        services: {
            type: Object,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    emits: ['obterAcaoAtualizada', 'fechar'],

    data() {
        return {
            tipos: [],
            descricao: null,
            tipo: null,
            quantidade: 0,
            aplicacaoMeta: null,
            erros: [],
        };
    },

    validations() {
        return {
            descricao: { required },
            tipo: { required },
            quantidade: { required },
            aplicacaoMeta: { required },
        };
    },

    methods: {
        obterTipos() {
            this.$store.dispatch('addRequest');
            this.services.obterMetasTipos(this.$route.params.id, this.acao.convenioAcaoId).then((response) => {
                if (response?.success) {
                    this.tipos = [...response.data];
                    if (this.tipos) {
                        this.tipo = this.tipos[0].convenioAcaoMetaTipoId;
                    }
                } else {
                    this.tipos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.operacao == 'I' ? 'inserir' : 'atualizar'} a meta?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            let promisse;
            let metaDto = {
                idCnt: this.convenio.idCnt,
                convenioAcaoId: this.acao.convenioAcaoId,
                descricao: this.descricao,
                convenioAcaoMetaTipoId: this.tipo,
                quantidade: this.quantidade,
                aplicacaoMeta: this.aplicacaoMeta,
            };

            if (this.operacao == 'A') {
                metaDto.convenioAcaoMetaId = this.meta.convenioAcaoMetaId;
                promisse = this.services.atualizarMeta(metaDto);
            } else {
                promisse = this.services.inserirMeta(metaDto);
            }

            promisse.then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Ação - Metas',
                        detail: `Meta ${this.operacao == 'I' ? 'inserida' : 'atualizada'} com sucesso`,
                        life: 3000,
                    });
                    this.$emit('obterAcaoAtualizada');
                    this.$emit('fechar');
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        cancelar() {
            this.$emit('fechar');
        },

        preencherForm() {
            this.descricao = this.meta.descricao;
            this.tipo = this.meta.convenioAcaoMetaTipoId;
            this.quantidade = this.meta.quantidade;
            this.aplicacaoMeta = this.meta.aplicacaoMeta;
        },
    },

    created() {
        if (this.operacao == 'A') {
            this.preencherForm();
        }
        this.obterTipos();
    },
};
</script>
