<template>
    <erros-box :erros="erros"></erros-box>
    <div class="grid">
        <div class="col-12" v-show="operacao == 'listar'">
            <tabela
                :data="extratos"
                dataKey="convenioExtratoBancarioId"
                :globalFilterFields="['banco', 'agencia', 'conta', 'arquivoNome', 'dataInicialBr', 'dataFinalBr']"
                stateKey="dt-rm-ced-extratos"
            >
                <template #botoes>
                    <Button
                        label="Inserir Extrato"
                        icon="pi pi-plus"
                        iconPos="left"
                        class="p-button"
                        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-09')"
                        @click="abrirFormInserirExtrato()"
                    />
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <btn-excluir
                                v-if="!slotProps.data.extratoComTransacaoDetalhada"
                                :convenio="convenio"
                                :extratos="slotProps.data"
                                :services="obterServices()"
                                @obterExtratosBancariosAtualizados="obterExtratosBancarios($event)"
                                class="ml-1"
                            />
                            <i v-else v-tooltip="'Extrato com transações vinculadas.'" style="padding-left: 0.3rem" class="pi pi-exclamation-triangle"></i>
                        </template>
                    </Column>
                    <Column field="banco" header="Banco" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.banco }} </template>
                    </Column>
                    <Column field="agencia" header="Agência" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.agencia }} </template>
                    </Column>
                    <Column field="conta" header="Conta" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.conta }} </template>
                    </Column>
                    <Column field="dataInicial" header="Data Inicial" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.dataInicial, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="dataFinal" header="Data Final" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.dataFinal, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="arquivo" header="Arquivo" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.arquivoNome }} </template>
                    </Column>
                </template>
            </tabela>
        </div>

        <div class="col-12" v-if="operacao == 'inserir'">
            <inserir-extrato-form @salvar="inserirExtrato($event)" @fechar="operacao = 'listar'"></inserir-extrato-form>
        </div>
    </div>
</template>

<script>
import Services from './services';
import InserirExtratoForm from './InserirExtratoForm';
import BtnExcluir from './BtnExcluir.vue';

export default {
    emits: ['atualizar', 'obterTransacoesAtualizadas'],

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        InserirExtratoForm,
        BtnExcluir,
    },

    data() {
        return {
            operacao: 'listar',
            extratos: null,
            erros: [],
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterExtratosBancarios(excluiuExtrato = false) {
            this.$store.dispatch('addRequest');
            Services.obterExtratosBancarios(this.$route.params.id).then((response) => {
                this.extratos = response?.success ? [...response.data] : null;
                this.$store.dispatch('removeRequest');
            });

            if (excluiuExtrato) this.$emit('obterTransacoesAtualizadas');
        },

        abrirFormInserirExtrato() {
            this.operacao = 'inserir';
        },

        inserirExtrato(extrato) {
            this.$store.dispatch('addRequest');
            extrato.idCnt = this.convenio.idCnt;
            Services.inserirExtratoBancario(extrato).then((response) => {
                this.erros = response?.success ? [] : [...response.errors];
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Extratos Bancários',
                        detail: 'Extrato inserido com sucesso',
                        life: 3000,
                    });
                    this.operacao = 'listar';
                    this.obterExtratosBancarios();
                    this.$emit('obterTransacoesAtualizadas');
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    watch: {
        convenio() {
            if (this.convenio) this.obterExtratosBancarios();
        },
    },
};
</script>
