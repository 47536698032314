<template>
    <font-awesome-icon
        icon="link"
        size="lg"
        class="icon-button p-text-secondary"
        title="Vincular Detalhe"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-14')"
        @click="abrir()"
    />
    <Dialog
        v-model:visible="mostrarModal"
        :modal="true"
        :style="{ width: '60vw' }"
        @hide="cancelar()"
        @show="preencher()"
        :contentStyle="{ overflow: 'visible' }"
    >
        <template #header>
            <h5>Vincular {{ transacao.tipoTransacao }}</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <tabela
            :data="detalhes"
            dataKey="detalheId"
            :globalFilterFields="['idMov', 'descricao']"
            :mostrarPaginacao="false"
            stateKey="dt-rm-ced-transacoes-detalhes"
        >
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <font-awesome-icon
                            icon="check-circle"
                            size="lg"
                            class="icon-button p-text-primary"
                            title="Vincular Detalhe"
                            @click="confirmarVincular(slotProps.data)"
                        />
                    </template>
                </Column>
                <Column field="idMov" header="IdMov" :sortable="true">
                    <template #body="slotProps"> {{ slotProps.data.idMov }} </template>
                </Column>
                <Column field="descricao" header="Descrição" :sortable="true">
                    <template #body="slotProps"> {{ slotProps.data.descricao }} </template>
                </Column>
                <Column field="valor" header="Valor" :sortable="true">
                    <template #body="slotProps">
                        <div class="white-space-nowrap text-right">
                            <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                        </div>
                    </template>
                </Column>
            </template>
        </tabela>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
        </template>
    </Dialog>
</template>

<script>
import Services from './services';

export default {
    emits: ['obterTransacoesAtualizadas'],

    props: {
        transacao: {
            type: Object,
        },
    },

    data() {
        return {
            mostrarModal: false,
            detalhes: null,
            erros: [],
        };
    },

    computed: {
        tipoTransacao() {
            return this.transacao?.tipoTransacao?.semAcentos().apenasLetrasNumeros().toUpperCase();
        },
    },

    methods: {
        abrir() {
            this.mostrarModal = true;
        },

        cancelar() {
            this.mostrarModal = false;
        },

        obterTransacoesBancariasDetalhesDisponiveis() {
            this.$store.dispatch('addRequest');
            Services.obterTransacoesBancariasDetalhesDisponiveis(this.$route.params.id, this.tipoTransacao)
                .then((response) => {
                    if (response?.success) {
                        this.detalhes = [...response.data];
                    } else {
                        this.detalhes = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        preencher() {
            this.obterTransacoesBancariasDetalhesDisponiveis();
        },

        confirmarVincular(detalhe) {
            this.$confirm.require({
                message: `Tem certeza que deseja vincular o ${this.transacao.tipoTransacao} à transação?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.vincular(detalhe);
                },
            });
        },

        vincular(detalhe) {
            this.$store.dispatch('addRequest');
            let vinculoDto = {
                convenioTransacaoBancariaId: this.transacao.convenioTransacaoBancariaId,
                detalheId: detalhe.detalheId,
                tipo: this.tipoTransacao,
            };
            Services.vincularTransacaoBancariaDetalhe(this.$route.params.id, vinculoDto)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({ severity: 'success', summary: 'Transações', detail: 'Vinculada com sucesso', life: 3000 });
                        this.$emit('obterTransacoesAtualizadas');
                        this.cancelar();
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Transções', detail: response.errors[0], life: 3000 });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>
