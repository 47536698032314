<template>
    <font-awesome-icon
        icon="trash"
        @click="confirmar()"
        size="lg"
        class="icon-button p-text-danger"
        title="Excluir"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-13')"
    />
</template>

<script>
export default {
    props: {
        services: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        reembolso: {
            type: Object,
        },
    },

    emits: ['obterReembolsosAtualizados'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir o reembolso?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            this.services
                .excluir(this.convenio.idCnt, this.reembolso.convenioReembolsoId)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Ações',
                            detail: 'Ação excluída com sucesso',
                            life: 3000,
                        });
                        this.$emit('obterReembolsosAtualizados');
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Ações',
                            detail: response.errors[0],
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>
