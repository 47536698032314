<template>
    <slot></slot>
    <h5 class="heading-1">
        <span>{{ operacao == 'I' ? 'INSERIR' : 'ATUALIZAR' }} AÇÃO</span>
    </h5>
    <erros-box :erros="erros"></erros-box>
    <div class="formgrid grid p-fluid">
        <div class="field col-12">
            <label class="required">Ação</label>
            <InputText type="text" v-model="descricao" @input="v$.descricao.$touch()" />
            <small class="p-error" v-if="v$.descricao.$error">A descrição da ação é obrigatória</small>
        </div>
        <div class="field col-12 md:col-3">
            <label class="required">Valor Financeiro Convenente</label>
            <InputNumber v-model="valorFinanceiroConvenente" mode="currency" currency="BRL" locale="pt-BR" @input="v$.valorFinanceiroConvenente.$touch()" />
            <small class="p-error" v-if="v$.valorFinanceiroConvenente.$error">O valor financeiro do convenente é obrigatório</small>
        </div>
        <div class="field col-12 md:col-3">
            <label class="required">Valor Econômico Convenente</label>
            <InputNumber v-model="valorEconomicoConvenente" mode="currency" currency="BRL" locale="pt-BR" @input="v$.valorEconomicoConvenente.$touch()" />
            <small class="p-error" v-if="v$.valorEconomicoConvenente.$error">O valor econômico do convenente é obrigatório</small>
        </div>
        <div class="field col-12 md:col-3">
            <label class="required">Valor Financeiro SEBRAE</label>
            <InputNumber v-model="valorFinanceiroSebrae" mode="currency" currency="BRL" locale="pt-BR" @input="v$.valorFinanceiroSebrae.$touch()" />
            <small class="p-error" v-if="v$.valorFinanceiroSebrae.$error">O valor financeiro do SEBRAE é obrigatório</small>
        </div>
        <div class="field col-12 md:col-3">
            <label class="required">Valor Econômico SEBRAE</label>
            <InputNumber v-model="valorEconomicoSebrae" mode="currency" currency="BRL" locale="pt-BR" @input="v$.valorEconomicoSebrae.$touch()" />
            <small class="p-error" v-if="v$.valorEconomicoSebrae.$error">O valor econômico do SEBRAE é obrigatório</small>
        </div>
    </div>
    <slot name="botoes"></slot>
    <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar()" />
    <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        operacao: {
            type: String,
        },

        acao: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        services: {
            type: Object,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    emits: ['obterAcoesAtualizadas', 'fechar'],

    data() {
        return {
            descricao: null,
            valorFinanceiroConvenente: 0,
            valorEconomicoConvenente: 0,
            valorFinanceiroSebrae: 0,
            valorEconomicoSebrae: 0,
            erros: [],
        };
    },

    validations() {
        return {
            descricao: { required },
            valorFinanceiroConvenente: { required },
            valorEconomicoConvenente: { required },
            valorFinanceiroSebrae: { required },
            valorEconomicoSebrae: { required },
        };
    },

    methods: {
        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.operacao == 'I' ? 'inserir' : 'atualizar'} a ação?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            let promisse;
            let acaoDto = {
                idCnt: this.convenio.idCnt,
                descricao: this.descricao,
                valorFinanceiroConvenente: this.valorFinanceiroConvenente,
                valorEconomicoConvenente: this.valorEconomicoConvenente,
                valorFinanceiroSebrae: this.valorFinanceiroSebrae,
                valorEconomicoSebrae: this.valorEconomicoSebrae,
            };

            if (this.operacao == 'A') {
                acaoDto.convenioAcaoId = this.acao.convenioAcaoId;
                promisse = this.services.atualizarAcao(acaoDto);
            } else {
                promisse = this.services.inserirAcao(acaoDto);
            }

            promisse.then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Ações',
                        detail: `Ação ${this.operacao == 'I' ? 'inserida' : 'atualizada'} com sucesso`,
                        life: 3000,
                    });
                    this.$emit('obterAcoesAtualizadas');
                    this.$emit('fechar');
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        cancelar() {
            this.$emit('fechar');
        },

        preencherForm() {
            this.descricao = this.acao.descricao;
            this.valorFinanceiroConvenente = this.acao.valorFinanceiroConvenente;
            this.valorEconomicoConvenente = this.acao.valorEconomicoConvenente;
            this.valorFinanceiroSebrae = this.acao.valorFinanceiroSebrae;
            this.valorEconomicoSebrae = this.acao.valorEconomicoSebrae;
        },
    },

    created() {
        if (this.operacao == 'A') {
            this.preencherForm();
        }
    },
};
</script>
