<template>
    <TabView v-model:activeIndex="activeIndex">
        <TabPanel header="Ao SEBRAE"><listagem :convenio="convenio" tipo="SEBRAE"></listagem></TabPanel>
        <TabPanel header="Ao Parceiro"><listagem :convenio="convenio" tipo="PARCEIRO"></listagem></TabPanel>
    </TabView>
</template>

<script>
// import Conciliacao from './Conciliacao';
// import ExtratosBancarios from './ExtratosBancarios';
// import TransacoesBancarias from './TransacoesBancarias';
import Listagem from './Listagem';

export default {
    emits: ['atualizar'],

    data() {
        return {
            activeIndex: 0,
        };
    },

    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        Listagem,
        // Conciliacao,
        // ExtratosBancarios,
        // TransacoesBancarias,
    },

    methods: {
        // obterTransacoesAtualizadas() {
        //     this.$refs.transacoes.obterTransacoesBancarias();
        //     this.obterConciliacaoAtualizada();
        // },
        // obterConciliacaoAtualizada() {
        //     this.$refs.conciliacao.obterConciliacao();
        // },
    },
};
</script>