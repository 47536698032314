import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    obterAnexos(idCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/anexos`);
    },

    obterAnexo(idCnt, convenioAnexoId) {
        return axiosJwt.get(`${urlBase}/${idCnt}/anexos/${convenioAnexoId}`, {
            responseType: 'blob',
        });
    },

    inserirAnexo(anexo) {
        const formData = new FormData();
        formData.append('idCnt', anexo.idCnt);
        formData.append('chave', anexo.chave);
        formData.append('descricao', anexo.descricao);
        formData.append('arquivo', anexo.arquivo);
        return axiosJwt.post(`${urlBase}/${anexo.idCnt}/anexos`, formData, formDataType);
    },

    excluirAnexo(idCnt, convenioAnexoId) {
        return axiosJwt.delete(`${urlBase}/${idCnt}/anexos/${convenioAnexoId}`);
    }
};
