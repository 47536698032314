<template>
    <Button label="Inserir Empresa" icon="pi pi-plus" iconPos="left" @click="abrir()" class="p-button" v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-03')" />
    <empresa-form
        titulo="Inserir Empresa Beneficiada"
        :mostrar="mostrar"
        :erros="erros"
        :services="services"
        :convenio="convenio"
        :reset="resetForm"
        @cancelar="fechar()"
        @salvar="inserir($event)"
        @atualizarErros="erros = $event"
    ></empresa-form>
</template>

<script>
import EmpresaForm from './EmpresaForm';

export default {
    props: {
        services: {
            type: Object,
        },

        convenio: {
            type: Object,
        },
    },

    components: {
        EmpresaForm,
    },

    emits: ['atualizar'],

    data() {
        return {
            mostrar: false,
            erros: [],
            resetForm: false,
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },

        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            this.erros = [];
        },

        reset() {
            this.resetForm = true;
            this.erros = [];
        },

        inserir(empresa) {
            this.$store.dispatch('addRequest');
            let empresaInserida = {
                idCnt: this.convenio.idCnt,
                cpfCnpj: empresa.cpfCnpj,
                nome: empresa.empresa,
            };
            this.services.inserirEmpresa(empresaInserida).then((r) => {
                if (r) {
                    if (r.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Empresas Beneficiadas',
                            detail: 'Empresa inserida com sucesso',
                            life: 3000,
                        });
                        this.$emit('atualizar');
                        this.reset();
                        this.fechar();
                    } else {
                        this.erros = r.errors;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
