import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterItemPorId(idCnt, nSeqItmCnt) {
        return axiosJwt.get(`${urlBase}/${idCnt}/itens/${nSeqItmCnt}`);
    },

    atualizarAporte(aporte) {
        return axiosJwt.post(`${urlBase}/${aporte.idCnt}/itens/${aporte.nSeqItmCnt}/aportes`, aporte);
    },
};
