<template>
    <detalhe titulo="Convênio" :size="size">
        {{ convenio?.convenio }}
    </detalhe>
    <detalhe titulo="Convênio Parceiro" :size="size">
        {{ convenio?.convenioParceiro }}
        <btn-atualizar-convenio-parceiro :convenio="convenio" @atualizar="atualizarConvenioParceiro($event)" />
    </detalhe>
    <detalhe titulo="Nome do Convênio" :size="size">
        {{ convenio?.objeto }}
    </detalhe>
    <detalhe titulo="Parceiro Executor" :size="size">
        <span v-if="convenio">({{ convenio.codFilial }}) {{ convenio.filial }}</span>
    </detalhe>
    <detalhe titulo="Parceiro Cedente" :size="size">
        <span v-if="convenio">({{ convenio.codCfo }}) {{ convenio.fornecedor }} - {{ $toCpfCnpj(convenio.cpfCnpj) }}</span>
    </detalhe>
    <detalhe titulo="Tipo do Convênio" :size="size">
        <span v-if="convenio">({{ convenio.codTipoContrato }}) {{ convenio.tipoContrato }}</span>
    </detalhe>
    <detalhe titulo="Status" :size="size">
        <span v-if="convenio">({{ convenio.codStatus }}) {{ convenio.status }}</span>
    </detalhe>
    <detalhe titulo="Valor do Convênio" :size="size">
        <span v-if="convenio">{{ $numeral(convenio.valorContrato).format('$0,0.00') }}</span>
    </detalhe>
    <detalhe titulo="Natureza" :size="size">
        {{ convenio?.naturezaDescr }}
    </detalhe>
    <detalhe titulo="Tipo para Faturamento" :size="size">
        <span v-if="convenio?.tipoFaturamento">({{ convenio.codTipoFaturamento }}) {{ convenio.tipoFaturamento }}</span>
    </detalhe>
    <detalhe titulo="Condição de Pagamento" :size="size">
        <span v-if="convenio?.condicaoPagamento">({{ convenio.codCondicaoPagamento }}) {{ convenio.condicaoPagamento }}</span>
    </detalhe>
    <detalhe titulo="Classificação da Natureza" :size="size">
        <span v-if="convenio?.classificacaoNatureza">({{ convenio.codClassificacaoNatureza }}) {{ convenio.classificacaoNatureza }}</span>
    </detalhe>
    <detalhe titulo="Natureza do Pagamento" :size="size">
        <span v-if="convenio?.naturezaPagamento">({{ convenio.codNaturezaPagamento }}) {{ convenio.naturezaPagamento }}</span>
    </detalhe>
    <detalhe titulo="Forma de Pagamento" :size="size">
        <span v-if="convenio?.formaPagamento">({{ convenio.codFormaPagamento }}) {{ convenio.formaPagamento }}</span>
    </detalhe>
    <detalhe titulo="Gestor" :size="size">
        <span v-if="convenio?.gestor">({{ convenio.codGestor }}) {{ convenio.gestor }} - {{ convenio.emailGestor }}</span>
    </detalhe>
    <detalhe titulo="Gerente" :size="size">
        <span v-if="convenio?.gerente">({{ convenio.codGerente }}) {{ convenio.gerente }} - {{ convenio.emailGerente }}</span>
    </detalhe>
    <detalhe titulo="Representante" :size="size">
        <span v-if="convenio?.representante">({{ convenio.codRepresentante }}) {{ convenio.representante }} - {{ convenio.emailRepresentante }}</span>
    </detalhe>
</template>

<script>
import BtnAtualizarConvenioParceiro from './BtnAtualizarConvenioParceiro';

export default {
    components: { BtnAtualizarConvenioParceiro },
    props: {
        convenio: {
            type: Object,
        },
    },
    emits: ['atualizar'],
    data() {
        return {
            size: '160',
        };
    },
    methods: {
        atualizarConvenioParceiro(convenioParceiro) {
            this.$emit('atualizar', convenioParceiro);
        },
    },
};
</script>
