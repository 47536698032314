<template>
    <font-awesome-icon icon="edit" @click="abrir()" size="lg" class="icon-button p-text-primary" title="Atualizar" />
    <aporte-form
        acao="A"
        :mostrar="mostrar"
        :titulo="`Atualizar Aporte - ${aporte.tipoAporteDescr}`"
        :aporte="aporte"
        @cancelar="fechar()"
        @salvar="atualizar($event)"
        :erros="erros"
    ></aporte-form>
</template>

<script>
import AporteForm from './AporteForm';
import ConveniosServices from './services';

export default {
    components: {
        AporteForm,
    },

    props: {
        aporte: {
            type: Object,
        },
    },

    emits: ['atualizarItem'],

    data() {
        return {
            mostrar: false,
            regraBase: null,
            erros: [],
            atualizado: false,
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },

        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            if (this.atualizado) {
                this.$emit('atualizar');
                this.atualizado = false;
            }
            this.erros = [];
        },

        atualizar(aporte) {
            this.$store.dispatch('addRequest');
            ConveniosServices.atualizarAporte(aporte).then((r) => {
                if (r) {
                    if (r.success) {
                        this.atualizado = true;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Aporte',
                            detail: 'Atualizado com sucesso',
                            life: 3000,
                        });
                        this.fechar();
                    } else {
                        this.erros = r.errors;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
