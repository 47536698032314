<template>
    <div class="grid">
        <div class="col-12" v-show="operacao == 'listar'">
            <Message :closable="false"
                >TOTAL REEMBOLSOS: <strong>{{ $numeral(valorTotal).format('$0,0.00') }}</strong></Message
            >
            <erros-box :erros="erros"></erros-box>
            <tabela
                :data="reembolsos"
                dataKey="convenioReembolsoId"
                :globalFilterFields="['acao', 'descricao', 'idMov']"
                :stateKey="'dt-rm-ced-reembolsos-' + tipo"
            >
                <template #botoes>
                    <btn-inserir @click="operacao = 'inserir'"></btn-inserir>
                    <btn-vincular @click="operacao = 'vincular'" class="ml-1"></btn-vincular>
                </template>
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <div class="flex flex-nowrap">
                                <btn-atualizar
                                    @click="abrirAtualizacao(slotProps.data)"
                                    v-if="!slotProps.data.vinculado && !slotProps.data.comprometido"
                                ></btn-atualizar>
                                <btn-excluir
                                    :convenio="convenio"
                                    :reembolso="slotProps.data"
                                    :services="obterServices()"
                                    @obterReembolsosAtualizados="obterReembolsos()"
                                    class="ml-1"
                                    v-if="
                                        ((!slotProps.data.vinculado && !slotProps.data.comprometido) ||
                                            (slotProps.data.vinculado && slotProps.data.comprometido)) &&
                                        !slotProps.data.temTransacoesBancarias
                                    "
                                ></btn-excluir>
                                <btn-comprometer
                                    :convenio="convenio"
                                    :reembolso="slotProps.data"
                                    :services="obterServices()"
                                    @obterReembolsosAtualizados="obterReembolsos()"
                                    @informarErro="erros = $event"
                                    class="ml-1"
                                    v-if="!slotProps.data.vinculado && !slotProps.data.comprometido"
                                >
                                </btn-comprometer>
                                <btn-descomprometer
                                    :convenio="convenio"
                                    :reembolso="slotProps.data"
                                    :services="obterServices()"
                                    @obterReembolsosAtualizados="obterReembolsos()"
                                    @informarErro="erros = $event"
                                    class="ml-1"
                                    v-if="slotProps.data.comprometido && !slotProps.data.vinculado"
                                >
                                </btn-descomprometer>
                            </div>
                        </template>
                    </Column>
                    <Column field="idMov" header="ID RM" :sortable="true">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.idMov">{{ slotProps.data.idMov }}</span>
                            <span v-if="slotProps.data.vinculado"><br /><status status="VINCULADO"></status></span>
                            <span v-if="slotProps.data.comprometido && !slotProps.data.vinculado"><br /><status status="COMPROMETIDO"></status></span>
                            <span v-if="!slotProps.data.comprometido"><status status="NÃO COMPROMETIDO"></status></span
                        ></template>
                    </Column>
                    <Column field="dataEmissao" header="Data Emissão" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.dataEmissao, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="dataMovimento" header="Data Movimento" :sortable="true">
                        <template #body="slotProps"> {{ $dateFormat(slotProps.data.dataMovimento, 'DD/MM/YYYY') }} </template>
                    </Column>
                    <Column field="historico" header="Histórico" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricao }}
                        </template>
                    </Column>
                    <Column field="valor" header="Valor" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                </template>
            </tabela>
        </div>
        <div class="col-12" v-if="operacao == 'vincular'">
            <pesquisar-movimentos
                @fechar="operacao = 'listar'"
                @selecionar="confirmarVincular($event)"
                :convenio="convenio"
                :tipoReembolso="tipo == 'SEBRAE' ? 0 : 1"
                tipo="RE"
            >
                <h5 class="heading-1">
                    <span>Vincular Reembolsos</span>
                </h5>
            </pesquisar-movimentos>
        </div>
        <div class="col-12" v-if="operacao == 'inserir' || (operacao == 'atualizar' && reembolso)">
            <reembolso-form
                :convenio="convenio"
                :operacao="operacao == 'inserir' ? 'I' : 'A'"
                :reembolso="reembolso"
                :tipo="tipo"
                :services="obterServices()"
                @fechar="fecharForm($event)"
            ></reembolso-form>
        </div>
    </div>
</template>

<script>
import BtnInserir from './BtnInserir';
import ReembolsoForm from './ReembolsoForm';
import BtnAtualizar from './BtnAtualizar';
import BtnExcluir from './BtnExcluir';
import BtnVincular from './BtnVincular';
import BtnComprometer from './BtnComprometer';
import BtnDescomprometer from './BtnDescomprometer';
import PesquisarMovimentos from '../movimentos/PesquisarMovimentos';
import Services from './services';

export default {
    props: {
        convenio: {
            type: Object,
        },

        tipo: {
            type: String,
            default: 'SEBRAE',
        },
    },

    components: {
        BtnInserir,
        BtnAtualizar,
        ReembolsoForm,
        BtnExcluir,
        BtnVincular,
        BtnComprometer,
        BtnDescomprometer,
        PesquisarMovimentos,
    },

    data() {
        return {
            operacao: 'listar',
            reembolsos: null,
            valorTotal: 0,
            reembolso: null,
            erros: [],
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        obterReembolsos() {
            this.$store.dispatch('addRequest');
            Services.obterReembolsos(this.$route.params.id, this.tipo.toLowerCase())
                .then((response) => {
                    if (response?.success) {
                        this.reembolsos = [...response.data.reembolsos];
                        this.valorTotal = response.data.valorTotal;
                    } else {
                        this.reembolsos = null;
                        this.valorTotal = 0;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        fecharForm(obterListagemAtualizada) {
            if (obterListagemAtualizada) {
                this.obterReembolsos();
            }
            this.operacao = 'listar';
        },

        confirmarVincular(idsRm) {
            this.$confirm.require({
                message: `Tem certeza que deseja vincular os movimentos selecionados`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.vincular(idsRm);
                },
            });
        },

        vincular(idsRm) {
            this.$store.dispatch('addRequest');
            let reembolsos = {
                idCnt: this.convenio.idCnt,
                idsRm: idsRm,
                tipo: this.tipo == 'SEBRAE' ? 0 : 1,
            };
            Services.vincularReembolsos(reembolsos)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Reembolsos',
                            detail: 'Vinculos realizados com sucesso',
                            life: 3000,
                        });
                        this.operacao = 'listar';
                        this.obterReembolsos();
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Despesas',
                            detail: response.errors[0],
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        abrirAtualizacao(reembolso) {
            this.reembolso = reembolso;
            this.operacao = 'atualizar';
        },
    },

    watch: {
        convenio() {
            if (this.convenio) {
                this.obterReembolsos();
            }
        },
    },
};
</script>