<template>
    <font-awesome-icon
        icon="unlink"
        size="lg"
        class="icon-button p-text-secondary"
        title="Desvincular Detalhe"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-14')"
        @click="confirmarDesvincular()"
    />
</template>

<script>
import Services from './services';

export default {
    emits: ['obterTransacoesAtualizadas'],

    props: {
        transacao: {
            type: Object,
        },
    },

    data() {
        return {
            erros: [],
        };
    },

    methods: {
        confirmarDesvincular() {
            this.$confirm.require({
                message: `Tem certeza que deseja desvincular o ${this.transacao.tipoTransacao} da transação?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.desvincular();
                },
            });
        },

        desvincular() {
            this.$store.dispatch('addRequest');
            Services.desvincularTransacaoBancariaDetalhe(this.$route.params.id, this.transacao.convenioTransacaoBancariaId)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({ severity: 'success', summary: 'Transações', detail: 'Desvinculada com sucesso', life: 3000 });
                        this.$emit('obterTransacoesAtualizadas');
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Transções', detail: response.errors[0], life: 3000 });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>