<template>
    <font-awesome-icon
        icon="trash"
        @click="confirmar()"
        size="lg"
        class="icon-button p-text-danger"
        title="Excluir"
        v-if="$temAcessoView('RM-CONV-EXEC-DIRETA-05')"
    />
</template>

<script>
export default {
    props: {
        services: {
            type: Object,
        },

        convenio: {
            type: Object,
        },

        despesa: {
            type: Object,
        },
    },

    emits: ['obterDespesasAtualizadas'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir a despesa?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            this.services.excluirDespesa(this.convenio.idCnt, this.despesa.convenioDespesaId).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Despesas',
                        detail: 'Despesa excluída com sucesso',
                        life: 3000,
                    });
                    this.$emit('obterDespesasAtualizadas');
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Despesas',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
