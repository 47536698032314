<template>
    <div class="grid" v-if="mostrarListagem">
        <div class="col-12">
            <tabela
                :data="convenio?.itens"
                dataKey="nSeqItmCnd"
                :globalFilterFields="['nSeqItmCnt', 'produto', 'codigoPrd', 'status', 'codStatus']"
                stateKey="dt-rm-convenios-execucao-direta-itens"
            >
                <template #conteudo>
                    <Column headerStyle="width: 3em">
                        <template #body="slotProps">
                            <font-awesome-icon
                                icon="info-circle"
                                size="lg"
                                class="icon-button p-text-primary"
                                title="Detalhar"
                                @click="detalharItem(slotProps.data)"
                            />
                        </template>
                    </Column>
                    <Column field="nSeqItmCnt" header="Sequencial" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.nSeqItmCnt }}
                        </template>
                    </Column>
                    <Column field="produto" header="Produto" :sortable="true">
                        <template #body="slotProps"> ({{ slotProps.data.codigoPrd }}) {{ slotProps.data.produto }} </template>
                    </Column>
                    <Column field="status" header="Status" :sortable="true">
                        <template #body="slotProps"> ({{ slotProps.data.codStatus }}) {{ slotProps.data.status }} </template>
                    </Column>
                    <Column field="quantidade" header="Quantidade" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.quantidade }}
                        </template>
                    </Column>
                    <Column field="precoFaturamento" header="Preço Faturamento" :sortable="true">
                        <template #body="slotProps">
                            <div class="white-space-nowrap text-right">
                                <span>{{ $numeral(slotProps.data.precoFaturamento).format('$0,0.00') }}</span>
                            </div>
                        </template>
                    </Column>
                </template>
            </tabela>
            <convenio-aportes :convenio="convenio"></convenio-aportes>
        </div>
    </div>
    <item-detalhe v-if="!mostrarListagem" :item="itemSelecionado" @fecharDetalhe="fecharDetalhe()"></item-detalhe>
</template>

<script>
import ItemDetalhe from './ItemDetalhe';
import ConvenioAportes from './ConvenioAportes';

export default {
    props: {
        convenio: {
            type: Object,
        },
    },

    components: {
        ItemDetalhe,
        ConvenioAportes,
    },

    data() {
        return {
            mostrarListagem: true,
            itemSelecionado: null,
        };
    },

    methods: {
        detalharItem(item) {
            this.itemSelecionado = item;
            this.mostrarListagem = false;
        },

        fecharDetalhe() {
            this.mostrarListagem = true;
            this.itemSelecionado = null;
        },
    },
};
</script>
