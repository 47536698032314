import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONVENIOSEXECUCAODIRETA_PORT}${process.env.VUE_APP_API_PATH}/conveniosexecucaodireta`;
const urlBase = `${api}`;

export default {
    obterProjetos() {
        return axiosJwt.get(`${urlBase}/movimentos/projetos`);
    },

    obterMovimentos(filtro) {
        return axiosJwt.get(`${urlBase}/movimentos/pesquisar?idcnt=${filtro.idCnt
            }&idmov=${filtro.idMov
            }&codigo=${filtro.codigo
            }&status=${filtro.status
            }&dataemissaode=${filtro.dataEmissaoDe
            }&dataemissaoate=${filtro.dataEmissaoAte
            }&projeto=${filtro.projeto
            }&fornecedor=${filtro.fornecedor
            }&tipo=${filtro.tipo}`);
    },
};
