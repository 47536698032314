<template>
    <Dialog v-model:visible="display" :modal="true" :style="{ width: '60vw' }" @hide="cancelar()" @show="preencher()" :contentStyle="{ overflow: 'visible' }">
        <template #header>
            <h5>Reconhecimento de receita</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-4 lg:col-3">
                <label class="required">Data da Contabilização</label>
                <InputText type="date" v-model="dataDaContabilizacao" @input="v$.dataDaContabilizacao.$touch()" />
                <small class="p-error" v-if="v$.dataDaContabilizacao.$error">A data é obrigatório</small>
            </div>
            <div class="field col-12 md:col-8">
                <label class="required">Histórico</label>
                <Textarea type="text" v-model="historico" @input="v$.historico.$touch()" maxlength="250" />
                <small class="p-error" v-if="v$.historico.$error">O histórico é obrigatório</small>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmarSalvar()" :disabled="naoPermiteSalvar" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        mostrar: { type: Boolean },
        convenio: { type: Object },
        erros: { type: Array },
    },

    emits: ['cancelar', 'salvar'],

    data() {
        return {
            display: false,
            dataDaContabilizacao: null,
            historico: null,
        };
    },

    validations() {
        return {
            dataDaContabilizacao: { required },
            historico: { required },
        };
    },

    methods: {
        cancelar() {
            this.$emit('cancelar');
        },

        preencher() {
            const dataAtual = new Date();
            const mesAno = dataAtual.toLocaleString('default', { month: 'long' }).toUpperCase();
            const ano = dataAtual.getFullYear();

            this.dataDaContabilizacao = this.$moment(dataAtual).format('YYYY-MM-DD');
            this.historico = `Reconhecimento de receita sobre execução do convênio ${this.convenio.convenio} – PARCEIRO, mês ${mesAno}/${ano}, Projeto ${this.convenio.objeto}.`;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja reconhecer a receita das despesas selecionadas?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            const reconhecimentoDeDespesa = {
                dataDaContabilizacao: this.$dateFormat(this.dataDaContabilizacao, 'DD/MM/YYYY'),
                historico: this.historico,
            };

            this.$emit('salvar', reconhecimentoDeDespesa);
        },
    },

    watch: {
        mostrar() {
            this.display = this.mostrar;
        },

        dataDaContabilizacao() {
            if (!this.historico) return;

            const mesAno = new Date(this.dataDaContabilizacao).toLocaleString('default', { month: 'long' }).toUpperCase();
            const ano = new Date(this.dataDaContabilizacao).getFullYear();

            this.historico = `Reconhecimento de receita sobre execução do convênio ${this.convenio.convenio} – PARCEIRO, mês ${mesAno}/${ano}, Projeto ${this.convenio.objeto}.`;
            this.v$.$touch();
        },
    },

    computed: {
        naoPermiteSalvar() {
            return this.v$.$invalid;
        },
    },
};
</script>
